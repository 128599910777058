@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.label {
  position: absolute;
  z-index: 10;
  left: 0px;
  top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  color: $global-inverse-color;
  text-transform: uppercase;
  padding: 0px 4px;

  @media (max-width: 640px) {
    margin-top: 1px;
  }
}

.labelText {
  font-family: $global-font-family-tertiary;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;

  &.withIcon {
    margin-left: 3px;
  }
}

.left {
  border-radius: 0px 2px 2px 0px;
}

.right {
  border-radius: 2px 0px 0px 2px;
}

.both {
  border-radius: 2px;
}
