/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: flex;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  min-height: 1px;
  padding: 0 8px;
  box-sizing: border-box;
  height: inherit;
  @media (max-width: $breakpoint-xsmall-max) {
    padding: 0;
  }
  > div {
    width: 100%;
    height: inherit;
  }
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: flex;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-dots {
  padding: 0;
  margin: 20px 0 0;
  list-style: none;
  text-align: center;
  li {
    display: inline-flex;
    margin: 0 3px;
    button {
      cursor: pointer;
      outline: none;
      padding: 0;
      border: 0;
      margin: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      text-indent: -9999px;
      overflow: hidden;
      background: #f5f5f5;
      box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.16);
      &:hover {
        background: var(--colour-version-website);
      }
    }
    &.slick-active {
      button {
        background: var(--colour-version-website);
      }
    }
  }
}
.slick-arrow {
  position: absolute;
  left: -22px;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  background: transparent;
  color: #fff;
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
  border: 2px solid var(--colour-version-website);
  &.slick-disabled {
    display: none !important;
  }
  @media (max-width: $breakpoint-small-max) {
    width: 37px;
    height: 37px;
    left: -8px;
  }
  &:before {
    display: none;
  }
  &.slick-next {
    left: auto;
    right: -22px;
    @media (max-width: $breakpoint-small-max) {
      right: -8px;
    }
  }
  &:hover,
  &:focus,
  &:active {
    background: transparent;
  }
}
