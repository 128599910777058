@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.dropdown {
  border-radius: 2px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  z-index: 1;
  &.open {
    box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.18);
    border-radius: 2px 2px 0px 0px;
    white-space: nowrap;

    > :first-child {
      border-radius: 2px 2px 0px 0px;
      button {
        border-radius: 2px 2px 0px 0px;
      }
    }
  }
  &.share {
    box-shadow: none;
    border-radius: 2px;
  }
  .dropdownChild {
    border-radius: 2px;
    svg path {
      stroke: $gray-dark;
      fill: none;
    }
  }
}

.isRounded {
  &.open {
    border-radius: 999px;

    > :first-child {
      border-radius: 999px;
      button {
        border-radius: 999px;
      }
    }
  }
  &.share {
    box-shadow: none;
    border-radius: 999px;
  }

  .content {
    margin-top: 8px;
    &.open {
      border-radius: 12px;
    }
  }
}

.dropdownChild {
  width: auto;
  box-sizing: border-box;
  display: inline-block;
  z-index: 50;
  position: relative;
  background-color: $tint-gray-light;
  color: $gray-dark;
  svg path {
    stroke: red;
    fill: none;
  }

  button > * > *:last-child {
    transition: transform 200ms;
  }

  :focus {
    outline: none;
  }

  &--has-selected {
    svg path {
      stroke: white !important;
      fill: none;
    }
  }
  &--open {
    button:hover {
      background: white;
    }

    button > * > *:last-child {
      transform: rotate(-180deg);
    }
  }
  &--share {
    button:hover {
      background: #dedede;
    }
  }
  &--white {
    background-color: transparent;

    svg {
      path {
        stroke: $secondary-brand-primary !important;
      }
    }
  }
}

.content {
  margin-top: 0;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.18);
  position: absolute;
  padding-top: 10px;
  left: 0;
  bottom: auto;
  overflow: hidden;
  padding-bottom: 0px;
  padding-left: 16px;
  padding-right: 16px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 300ms;

  &.open {
    border-radius: 0px 2px 2px 2px;
    opacity: 1;
    visibility: visible;
  }
  &.share {
    margin-top: 8px;
    border-radius: 2px;
    min-width: 160px;
    padding: 0;
  }
}

.counter {
  font-size: 15px;
  color: $gray-light;
}

.filter-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  &-ul {
    list-style: none;
    width: 230px;
  }
  &-li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  cursor: pointer;
}
