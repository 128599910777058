@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
$buttonVariants: "primary-default" $global-inverse-color $global-inverse-color,
  "primary-brand" $global-inverse-color $global-inverse-color, "primary-inverted" $gray-dark $gray-dark,
  "secondary" $gray-dark $gray-dark, "secondary-inverted" $gray-dark $gray-dark,
  "tertiary" $gray-dark (var(--colour-version-website-darken7)),
  "tertiary-inverted" $global-inverse-color ($global-inverse-color);

@each $name, $color, $colorHover in $buttonVariants {
  .button--#{$name}--icon-filled {
    .icon-svg path {
      fill: $color;
    }
    &:hover .icon-svg path {
      fill: $colorHover;
    }
  }

  .button--#{$name}--icon-outline {
    .icon-svg path {
      stroke: $color;
    }
    &:hover .icon-svg path {
      stroke: $colorHover;
    }
  }

  .button--#{$name}--icon-outline-fill {
    .icon-svg path {
      fill: $color;
      stroke: $color;
    }
    &:hover .icon-svg path {
      fill: $colorHover;
      stroke: $colorHover;
    }
  }
}

.button,
.button--primary-default {
  margin: 0;
  border: none;
  box-sizing: border-box !important;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-size: 15px;
  line-height: 1.3;
  font-weight: 600;
  font-family: $global-font-family;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  user-select: none;
  justify-content: center;
  position: relative;
  border-radius: 2px;
  padding: 8px 24px;
  transition: background-color 0.05s;
  cursor: pointer;
  color: $global-inverse-color !important;
  background-color: $brand-secondary;
  @media (max-width: $breakpoint-xsmall-max) {
    width: 100%;
  }
  .icon-svg {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &:hover,
  .button--primary-default:hover {
    text-decoration: none;
    background-color: darken($global-secondary-background, 7%);
  }

  &--primary-brand {
    background-color: $gray-dark;
    &:hover {
      background-color: lighten($gray-dark, 10%);
    }
  }

  &--primary-new {
    background-color: $tertiary-brand-primary;
    &:hover {
      background-color: lighten($tertiary-brand-primary, 10%);
    }
  }

  &--primary-inverted,
  &--secondary-inverted {
    color: $gray-dark !important;
    background-color: $global-background;
    &:hover {
      background-color: $global-muted-background;
    }
  }

  &--secondary {
    color: $gray-dark !important;
    background-color: $tint-gray-light;
    &:hover {
      background-color: darken($tint-gray-light, 7%);
    }
  }

  &--tertiary {
    color: $gray-dark !important;
    background-color: transparent;
    -webkit-box-shadow: inset 0px 0px 0px 2px var(--colour-version-website);
    -moz-box-shadow: inset 0px 0px 0px 2px var(--colour-version-website);
    box-shadow: inset 0px 0px 0px 2px var(--colour-version-website);
    &:hover {
      -webkit-box-shadow: inset 0px 0px 0px 2px var(--colour-version-website-darken7);
      -moz-box-shadow: inset 0px 0px 0px 2px var(--colour-version-website-darken7);
      box-shadow: inset 0px 0px 0px 2px var(--colour-version-website-darken7);
      color: var(--colour-version-website-darken7) !important;
      background-color: var(--colour-version-website-lighten51) !important;
    }
  }

  &--tertiary-inverted {
    color: $global-inverse-color;
    background-color: transparent;
    -webkit-box-shadow: inset 0px 0px 0px 2px var(--colour-version-website);
    -moz-box-shadow: inset 0px 0px 0px 2px var(--colour-version-website);
    box-shadow: inset 0px 0px 0px 2px var(--colour-version-website);

    &:hover {
      -webkit-box-shadow: inset 0px 0px 0px 2px var(--colour-version-website-darken7);
      -moz-box-shadow: inset 0px 0px 0px 2px var(--colour-version-website-darken7);
      box-shadow: inset 0px 0px 0px 2px var(--colour-version-website-darken7);
      background-color: var(--colour-version-website-darken35) !important;
    }
  }

  &--FAB {
    padding: 0;
    width: 40px;
    height: 40px;
  }

  &--small {
    padding: 8px 18px;
  }
}

.icon-left {
  margin-right: 8px;
  display: inherit;
}

.icon-right {
  margin-left: 8px;
  display: inherit;
}
