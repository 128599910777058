.newTextField {
  input {
    border: 1px solid $tint-gray;
    font-family: $global-font-family-tertiary;
    font-size: 14px;
    background: white;
    border-radius: 2px 0px 0px 2px;
    height: 46px;
    padding: 12px 16px 12px 16px;
    line-height: 1;

    &:focus {
      outline: none;
      border: 1px solid $gray-light;
    }
  }

  .form-field__extra {
    display: none;
    &.hasError {
      display: inline-flex;
    }
  }
}
