@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.footerAction {
  flex: 0 1 auto;
  > p {
    margin-top: 0;
    margin-bottom: 15px;
  }
  button {
    width: 100%;
    @media (max-width: $breakpoint-medium) {
      width: 50%;
    }
    @media (max-width: $breakpoint-small) {
      width: 100%;
    }
  }
}

.content {
  display: flex;
  flex-direction: row;
  @media (max-width: $breakpoint-medium-max) {
    justify-content: center;
  }
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  > span {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;

    font-size: 14px;
    font-weight: 400;

    @media (max-width: $breakpoint-xsmall-max) {
      justify-content: center;
    }
  }

  @media (max-width: $breakpoint-xsmall-max) {
    justify-content: center;
  }
}

.columnTitle {
  font-size: 20px;
  font-family: $global-font-family-secondary;
  font-weight: 600;
  color: $global-background;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__icon {
    margin-left: 15px;
    path {
      stroke: #fff;
    }
  }
  @media (max-width: $breakpoint-xsmall-max) {
    padding: 14px 0;
    position: relative;
    margin: 0;
    justify-content: center;
  }
}

.arrowIcon {
  margin-left: auto;

  span > span > svg > path {
    stroke: var(--colour-version-website);
  }

  @media (max-width: $breakpoint-small) {
    right: 0;
    padding-right: 16px;
    position: absolute;
  }
}

.applyForm {
  margin-top: 24px;
  height: 70px; // So the interface doesn't jump on error;
  width: 400px;

  @media (max-width: $breakpoint-medium-max) {
    height: auto;
    margin-top: 16px;
    margin-bottom: 8px;
    width: 100%;
  }
}
