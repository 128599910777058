@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.placeholder {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > span {
    max-width: 100px;
    > span {
      width: 100%;
      height: 100%;
    }
  }
  .placeholder-text {
    margin-top: 8px;
    color: $gray-light;
  }
}

.svg-class {
  height: 100%;
  width: 100%;
  div,
  svg {
    height: 100%;
    width: 100%;
  }
}
