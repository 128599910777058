@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.ctaSmallNewsLetter {
  a,
  button {
    white-space: nowrap;
    @media (min-width: $breakpoint-medium-max) {
      width: auto;
    }
    @media (max-width: $breakpoint-medium-max) {
      width: 100%;
    }
  }
}

.center {
  padding: 16px;
  background: $global-background;
  border-radius: 2px;
}
.wrapper {
  border-radius: $global-border-radius;
  text-align: center;
  display: inline-block;
  justify-content: center;
  width: 100%;
  margin-top: 0;
  @media (min-width: $breakpoint-medium-max) {
    display: flex;
    align-items: center;
    margin-top: -24px;
  }
}

.ctaText {
  margin-bottom: 0;
}

.infoText {
  color: $global-muted-color;
  font-size: 11px;
  text-align: center;
  font-family: $global-font-family-tertiary;
  margin: 0px;
}

.applyForm {
  margin-top: 24px;
  height: 70px; // So the interface doesn't jump on error;

  @media (max-width: $breakpoint-medium-max) {
    height: auto;
    margin-top: 16px;
    margin-bottom: 8px;
  }
}

.appliedForm {
  margin-top: 24px;
  margin-bottom: 16px;
  padding: 16px 32px 16px 32px;
  border: 2px solid $gray;
  text-align: center;

  @media (max-width: $breakpoint-medium-max) {
    height: auto;
    margin-top: 16px;
    margin-bottom: 8px;
  }
}

.arrowIcon {
  span {
    margin: 0px 32px 0px 32px;

    span > svg > path {
      stroke: var(--colour-version-website);
    }

    @media (max-width: $breakpoint-medium-max) {
      display: none;
    }
  }
}

.paperIcon {
  display: inline-block;
  height: 32px;
  width: 32px;
  margin-right: 24px;
  svg,
  img {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
  }

  @media (max-width: $breakpoint-medium-max) {
    margin-bottom: 16px;
    margin-right: 0;
  }
}
