@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.share {
  > div:not(:last-child) {
    border-bottom: 1px solid $tint-gray;
  }
  > div {
    margin-left: -16px;
    margin-right: -16px;
    padding: 8px 16px;
    cursor: pointer;
    &:hover {
      background-color: $bg-muted;
    }
  }
  .link {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    width: 100%;
  }

  .icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    flex: 0 0 auto;
    path {
      fill: $gray-dark;
    }
  }

  .title {
    font-size: 15px;
    color: $gray-dark;
    margin-left: 8px;
  }
}

.share.badges {
  display: flex;

  > div {
    border-bottom: 0;
    margin-left: 0;
    margin-right: 8px;
    padding: 0;
    &:hover {
      background-color: transparent;
    }
  }

  .icon {
    background: $gray-lighter;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;

    &:hover {
      opacity: 0.75;
    }
    path {
      fill: white;
    }

    &.facebook {
      background: #3b5998;
      svg {
        margin-left: -1px;
      }
    }
    &.twitter {
      background: #00aced;
    }
    &.whatsapp {
      background: #2cb742;
      span {
        width: 18px !important;
        height: 18px !important;
      }
    }
  }
}
