@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.icon-svg {
  display: inline-flex;
  span,
  svg,
  path {
    display: flex;
    width: inherit;
    height: inherit;
  }
  &--outline {
    span,
    svg,
    path {
      stroke: inherit;
    }
  }
  &--stroke-width {
    span,
    svg,
    path {
      stroke-width: inherit;
    }
  }
  &--filled {
    span,
    svg,
    path {
      fill: inherit;
    }
  }
}
