@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.not-found {
  background-color: $gray-dark;
  height: calc(100vh - 82px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/pattern.svg");
  @media (max-width: 960px) {
    margin-bottom: -69px;
  }
  &-container {
    flex-direction: column;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-position: 50% 0;
    background-size: 2000px auto;
    opacity: 0.5;
  }

  &-title {
    padding: 30px 0 0 0;
    font-family: $global-font-family-secondary;
    font-size: 48px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    @media (max-width: 600px) {
      font-size: 40px;
    }
  }
  a {
    font-family: $global-font-family;
    margin-top: 25px;
    width: auto;
    height: 36px;
    border-radius: 2px;
    @media (max-width: 600px) {
      width: 190px;
      height: 33px;
      font-size: 13px;
      margin-top: 20px;
    }
  }
  p {
    font-family: $global-font-family-secondary;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 12px;
    }
    @media (max-width: 420px) {
      padding: 0 5% 0 5%;
      white-space: pre-wrap;
    }
  }
}
