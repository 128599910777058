@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.body-text {
  color: $gray;

  h1,
  h2,
  h3,
  h4 {
    margin: 0 0 16px 0;
    &:not(:first-child) {
      margin: 40px 0 16px 0;
    }
    + p {
      margin: 0;
    }
  }

  @media (max-width: $breakpoint-small-max) {
    h1,
    h2,
    h3,
    h4 {
      margin: 0 0 8px 0;
      &:not(:first-child) {
        margin: 24px 0 8px 0;
      }
      + p {
        margin: 0;
      }
    }
  }

  p ~ ul,
  p ~ ol {
    margin-top: 8px;
    font-family: $global-font-family-tertiary;
  }

  a {
    font-family: $global-font-family-tertiary;
    color: var(--colour-version-website);
    text-decoration: none;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}

.body-text {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  font-family: $global-font-family-tertiary;

  display: flex;
  .body-text-container {
    max-width: $container-max-width;
  }

  .body-text-container-outer {
    max-width: $container-max-width;
    margin: auto;
    display: flex;
  }

  position: relative;
  @media (max-width: $breakpoint-small-max) {
    .uk-grid {
      > div:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
  &_foreground {
    position: relative;
  }

  &--left {
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: flex-end;
  }
}

.aligner {
  &,
  &.full {
    max-width: 100%;
  }
  &.large {
    max-width: calc(#{$container-max-width} * 0.8);
  }

  &.medium {
    max-width: calc(#{$container-max-width} * 0.5);
  }

  &.small {
    max-width: calc(#{$container-max-width} * 0.3);
  }
}
