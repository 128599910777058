@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
@import "~@webbio/components/components.umd.css";
@import "~uikit/src/scss/mixins.scss";

@import "~uikit/src/scss/variables.scss";

@import "~uikit/src/scss/components/base.scss";

@import "~uikit/src/scss/components/container.scss";
@import "~uikit/src/scss/components/grid.scss";
@import "~uikit/src/scss/components/width.scss";
@import "~uikit/src/scss/components/visibility.scss";
@import "~uikit/src/scss/components/accordion";
@import "~uikit/src/scss/components/dropdown";
@import "~uikit/src/scss/components/switcher";
@import "~uikit/src/scss/components/modal";

@import "./src/shared/styles/slick.scss";
@import "./src/shared/styles/form.scss";

@import "./src/shared/styles/fonts.scss";
@import "./src/shared/styles/base.scss";
@import "./src/shared/styles/placeholder.scss";

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --colour-version-website: #1c1c1c;
  --colour-version-website-RGB: 28, 28, 28;
  --colour-version-website-darken7: 20, 20, 20;
  --colour-version-website-darken16: 18, 18, 18;
  --colour-version-website-darken35: 16, 16, 16;
  --colour-version-website-lighten51: 34, 34, 34;
}

.async-hide {
  opacity: 0 !important;
}
