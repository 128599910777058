@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: $global-background;
  border-radius: $global-border-radius;
  box-shadow: $global-shadow-md;
  padding: 16px;
  height: 38px;
  overflow: hidden;
  -webkit-transition: all 0.05s;
  transition: all 0.05s;
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  &:hover {
    text-decoration: none;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    .title {
      color: var(--colour-version-website);
    }

    .icon {
      svg {
        stroke: var(--colour-version-website);
      }
    }
  }
  &:active {
    text-decoration: none;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
    transform: scale(1);
  }

  .content {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 8px;
  }

  .title {
    color: $global-color;
    font-weight: bold;
    font-family: $global-font-family-secondary;
    max-height: 40px;
    line-height: normal;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;

    @media (max-width: $breakpoint-xsmall-max) {
      font-size: 14px;
    }
  }

  .image-container {
    margin-right: 16px;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &__image {
      object-fit: cover;
      max-width: 100%;
      max-height: 70%;
    }

    &__icon {
      max-width: 100%;
      max-height: 70%;
    }
  }

  &--rounded {
    border-radius: 16px;
  }

  &--category {
    padding: 8px 16px;
    margin-right: 12px;
    .content {
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin-right: 0px;
    }

    .image-container {
      width: 120px;
      height: 120px;
      margin-right: 0px;
    }

    .title {
      font-size: 20px;
      max-width: 100%;
      max-height: 100%;
      text-align: center;
    }

    @media (max-width: $breakpoint-small) {
      .title {
        font-size: 16px;
      }
    }
  }

  &--big {
    width: 168px;
    height: 180px;
    .content {
      row-gap: 4px;
    }

    @media (max-width: $breakpoint-small) {
      width: 108px;
      height: 160px;

      .image-container {
        width: 100px;
        height: 100px;
      }
    }
  }

  &--small {
    width: 168px;
    height: 124px;

    @media (max-width: $breakpoint-small) {
      width: 108px;
      height: 84px;

      .image-container {
        max-width: 96px;
        max-height: 76px;
        margin-right: 0px;

        &__image {
          object-fit: cover;
          max-width: 100%;
          max-height: 100%;
        }

        &__icon {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
