@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.categoryBlocks {
  button {
    @include button-reset();
    opacity: 0.9;
    width: 32px;
    height: 32px;
    background: $gray;
    box-shadow: 0px 2px 0px $gray-dark;
    border-radius: 2px;
    cursor: pointer;
  }
}

.moduleTitle {
  font-family: $global-font-family-secondary;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 24px !important;
}

.prevButton {
  margin-bottom: 28px;
}

.nextButton {
  margin-bottom: 28px;
}

.categoryContainer {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 140px;
  .categoryimage {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    justify-content: center;
    img {
      border-radius: 2px;
      width: 140px;
      height: 140px;
      max-width: 140px;
      max-height: 140px;
      min-width: 140px;
      min-height: 140px;
      object-fit: cover;
    }
  }
}

.categoryTitle {
  margin-top: 8px;
  font-family: $global-font-family-secondary;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}
