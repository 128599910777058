@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
@import "./new-text-field-component.module.scss";

.form-field {
  display: flex;
  flex-direction: column;
  &__extra {
    margin: 4px 0;
    display: inline-flex;
    justify-content: space-between;
    &__helper,
    &__error {
      color: rgba(0, 0, 0, 0.6);
      font-family: Assistant, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 0.75rem;
      line-height: 1.25rem;
      font-weight: 400;
      letter-spacing: 0.0333333333em;
      text-decoration: inherit;
      text-transform: inherit;
      display: block;
      line-height: normal;
    }
    &__character-counter {
      color: rgba(0, 0, 0, 0.6);
      font-family: Assistant, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 0.75rem;
      line-height: 1.25rem;
      font-weight: 400;
      letter-spacing: 0.0333333333em;
      text-decoration: inherit;
      text-transform: inherit;
      display: block;
      margin-top: 0;
      line-height: normal;
      margin-left: auto;
      margin-right: 0;
      padding-left: 16px;
      padding-right: 0;
      white-space: nowrap;
      margin-left: 4px;
    }
  }
  &__label {
    font-family: Assistant;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $gray-dark;
    margin-bottom: 4px;
  }
  &__control {
    border: none;
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: transform, opacity;
    border-radius: 4px 4px 0 0;
    display: inline-flex;
    position: relative;
    box-sizing: border-box;
    height: auto;
    overflow: hidden;
    will-change: opacity, transform, color;
    overflow: visible;
  }

  &__input,
  &__textarea {
    display: flex;
    padding: 12px 16px 12px 18px;
    z-index: 1;
    font-family: Assistant, sans-serif;
    min-height: 36px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.009375em;
    text-decoration: inherit;
    text-transform: inherit;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    background: none;
    -webkit-appearance: none;
    background-color: $bg-muted;
    border: none;
    &:focus {
      border: $gray-dark;
    }
  }
  &__textarea {
    height: 100px;
  }
  &__focused {
    .form-field {
      &__notch {
        > * {
          border-color: black;
          border-width: 2px !important;
          text-overflow: clip;
          z-index: 10;
        }
        &-float {
          border-top: none;
          width: auto;
          > * {
            transform: translateY(-105%) scale(0.75);
            color: black;
          }
        }
      }
    }
  }
}
.secondary {
  .form-field {
    &__notch {
      > * {
        border-color: black !important;
      }
      &-float {
        > * {
          transform: translateY(-105%) scale(0.75);

          color: black !important;
        }
      }
    }
  }
}

.error {
  .form-field {
    &__extra {
      &__error {
        color: #b00020 !important;
      }
    }
    &__notch {
      border-color: #b00020 !important;
      > * {
        border-color: #b00020 !important;
      }
      &-float {
        > * {
          color: #b00020 !important;
          border-color: #b00020 !important;
        }
      }
    }
  }
}
.not-empty {
  .form-field {
    &__notch {
      > * {
        text-overflow: clip;
        z-index: 10;
      }
      &-float {
        border-top: none;
        width: auto;
        > * {
          transform: translateY(-105%) scale(0.75);
        }
      }
    }
  }
}

.hovered {
  .form-field {
    &__notch {
      > * {
        border-width: 2px !important;
      }
    }
  }
}
