@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.counter {
  font-size: 15px;
  color: $gray-light;
  margin-bottom: 0px;
}

.filter-bar {
  display: flex;
  justify-content: space-between;
  color: white;

  cursor: pointer;

  input {
    font-weight: 600;
  }
}
