@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.footerLanguages {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-shrink: 0;

  @media (max-width: $breakpoint-xsmall-max) {
    align-items: center;
    gap: 8px;
  }
}

.title {
  font-size: 14px;
  font-family: $global-font-family-secondary;
  font-weight: 600;
}

.list {
  display: flex;
  gap: 8px;

  a {
    display: flex;
    align-items: center;
    height: 22px;
    width: 22px;

    &:hover {
      img {
        transform: translateY(-3px);
      }
    }
  }

  img {
    transition: transform 0.15s;
    width: 16px;
  }
}
