@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.loader {
  width: 100%;
  left: 0;
  position: fixed;
  height: 3px;
  background-color: var(--colour-version-website-lighten51);
  z-index: 10000;
  top: 0;

  .barResize {
    position: absolute;
    width: 200px;
    background-color: var(--colour-version-website);
    height: 3px;
    animation: scale-bar 2s infinite ease-in-out;
  }
  .barPosition {
    animation: slide-bar 2s infinite linear;
  }
}

@keyframes scale-bar {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(2, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes slide-bar {
  0% {
    transform: translateX(-200px);
  }

  100% {
    transform: translateX(100vw);
  }
}
