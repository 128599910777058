@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.author {
  display: flex;
  width: 100%;
  gap: 24px;
  padding-top: 24px;
  border-top: 1px solid $global-border;
}

.meta {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  align-self: flex-start;
  flex-shrink: 0;
  max-width: 120px;
  color: inherit;

  &:hover {
    color: $secondary-brand-primary;
    text-decoration: none;
  }
}

.name {
  font-family: $global-font-family-tertiary;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}

.image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
}

.description {
  font-size: 14px;
  font-weight: 400;
  color: $gray;
  @include truncate-text(56, 3);
}

.about {
  display: flex;
  flex-direction: column;

  a {
    margin-top: auto;
    align-self: flex-start;
    margin-bottom: 2px;
    width: auto;
    font-size: 14px;
  }
}
