@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.searchResults {
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;

  @media (max-width: $breakpoint-small-medium) {
    padding-top: 16px;
    height: calc(100vh - 58px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.noResults {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid $global-border;

  .title {
    font-family: $global-font-family-secondary;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
    color: $gray-dark;

    @media (max-width: $breakpoint-small) {
      padding: 16px 16px 0px 16px;
    }
  }

  .steps {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    li {
      display: flex;
      align-items: center;

      ::before {
        content: none;
      }

      .icon {
        width: 16px;
        height: 16px;
        margin-top: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
      }
    }

    @media (max-width: $breakpoint-small) {
      padding: 0px 16px;
    }
  }
}

.searchResultContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .header {
    font-family: $global-font-family-secondary;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: $gray-dark;

    @media (max-width: $breakpoint-small-medium) {
      margin: 0 16px;
    }
  }

  .body {
    @media (max-width: $breakpoint-small) {
      border-top: 1px solid #e0e0e0;
    }
  }

  .button {
    @media (max-width: $breakpoint-small-medium) {
      padding: 0px 16px;
    }
  }
}

.pagePanel {
  margin: -8px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  > * {
    border-radius: $global-border-radius;
    margin: 0;
    box-shadow: none;
    box-sizing: border-box;
    width: 25%;
    padding: 4px;
    @media (max-width: 1200px) {
      width: 33.3333%;
    }

    @media (max-width: 768px) {
      width: 50%;
    }
    @media (max-width: $breakpoint-xsmall-max) {
      width: 100%;
      padding: 0;
    }
  }
  @media (max-width: $breakpoint-xsmall-max) {
    box-shadow: $global-shadow-md;
    border-radius: $global-border-radius;
    margin: 0;
    > div {
      padding-left: 0;
    }
    a {
      box-shadow: none;
      border-radius: 0px;
      &:hover {
        transform: none;
        box-shadow: none;
      }
    }

    div:not(:first-child) a {
      border-top: 1px solid $bg-muted;
    }
    div:first-child a {
      border-radius: $global-border-radius $global-border-radius 0px 0px;
    }
    div:last-child a {
      border-radius: 0px 0px $global-border-radius $global-border-radius;
    }
  }
}
