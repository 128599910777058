@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.header-content {
  display: flex;
  flex-direction: column;
  color: $gray-dark;

  h1,
  h2 {
    color: currentColor;
    &.subtitle {
      font-weight: normal;
    }
  }

  h1 {
    margin-bottom: 0px;
  }
}

.white {
  color: white;
}

.text {
  color: rgba(255, 255, 255, 0.5);
  margin-top: 8px;
}

@media (max-width: $breakpoint-xsmall-max) {
  .left {
    &.header-content {
      text-align: left;
      align-items: flex-start;
    }
  }

  .center {
    &.header-content {
      text-align: center;
    }
  }

  h2.subtitle {
    margin-top: 8px;
  }
}
