@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.footer {
  font-size: 13px;
  font-weight: 400;
  color: $gray-light;
  background: $global-background;

  &,
  a {
    font-family: $global-font-family-tertiary;
  }

  a {
    font-weight: 400;
  }

  &__top {
    padding: 20px 0;
  }

  &__top-holder {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    @media (min-width: $breakpoint-medium) {
      justify-content: space-between;
    }
  }
  &__top-left {
    flex: 0 1 25%;
    @media (max-width: $breakpoint-small-max) {
      flex: 0 1 auto;
    }
    a {
      text-decoration: none;
    }
  }
  &__top-right {
    flex: 0 1 75%;
  }
  &__top-list {
    display: flex;
    font-size: 15px;
    color: $gray-light;
    margin: 0;
    @media (max-width: $breakpoint-large) {
      font-size: 12px;
    }
    @media (max-width: $breakpoint-small-max) {
      font-size: 15px;
    }

    @media (min-width: $breakpoint-medium) {
      margin-left: -15px;
    }

    li {
      margin: 0 16px;
      a {
        display: flex;
        color: $gray-light;

        align-items: center;
        flex-direction: row;
        &:hover {
          color: $gray;
        }
      }
    }
    .icon-svg {
      margin-right: 10px;
    }
  }

  &__holder {
    background: $gray-dark;
    color: $global-inverse-color;
    padding: 48px 0 37px;
    @media (max-width: $breakpoint-xsmall-max) {
      padding: 16px 0;
    }
    a {
      text-decoration: none;
      color: $tint-gray;
      &:hover {
        color: $gray-lighter;
      }
    }
  }

  &__columns {
    @media (min-width: $breakpoint-small) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    @media (min-width: $breakpoint-medium) {
      flex-wrap: nowrap;
    }
    > div {
      @media (min-width: $breakpoint-small) {
        flex: 1 1 45%;
        width: 50%;
        &:not(:last-child) {
          padding-right: 32px;
        }

        padding-bottom: 16px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
      }
      @media (min-width: $breakpoint-medium) {
        flex: 1 1 auto;
        padding-bottom: 0;
      }
    }
  }

  &__column-description-section {
    display: flex;
    flex-direction: row;
    @media (max-width: $breakpoint-medium-max) {
      justify-content: center;
    }
  }

  &__column-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media (max-width: $breakpoint-xsmall-max) {
      justify-content: center;
    }
  }

  &__column-description-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    @media (max-width: $breakpoint-xsmall-max) {
      justify-content: center;
    }
  }

  &__column-description-item-icon {
    padding-right: 8px;
  }

  &__column-body {
    flex: 0 1 auto;
    > p {
      margin-top: 0;
      margin-bottom: 15px;
    }
    button {
      width: 100%;
      @media (max-width: $breakpoint-medium) {
        width: 50%;
      }
      @media (max-width: $breakpoint-small) {
        width: 100%;
      }
    }
  }

  &__column-footer {
    flex: 0 0 auto;
    margin-top: auto;
    margin-right: auto;
    @media (max-width: $breakpoint-small) {
      margin-top: 20px;
    }
  }

  &__column-collapse {
    @media (max-width: $breakpoint-xsmall-max) {
      border-bottom: 1px solid $gray;
    }
  }
  &__column-title {
    font-size: 20px;
    font-family: $global-font-family-secondary;
    font-weight: 600;
    color: $global-background;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__icon {
      margin-left: 15px;
      path {
        stroke: #fff;
      }
    }
    @media (max-width: $breakpoint-xsmall-max) {
      padding: 14px 0;
      position: relative;
      margin: 0;
      justify-content: center;
    }
  }

  &__column-nav {
    margin: 0;

    li {
      position: relative;
      list-style: none;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    li:hover {
      a {
        color: white;
      }
    }
  }

  &__bottom {
    padding: 15px 0;
  }

  &__bottom-holder {
    display: flex;
    justify-content: space-between;
    @media (max-width: $breakpoint-small-max) {
      flex-direction: column;
    }
    ul {
      margin: 0;
    }
  }

  &__logo-box {
    display: flex;
    align-items: center;
    flex: 0 1 50%;
    white-space: nowrap;
    @media (max-width: $breakpoint-small-max) {
      white-space: normal;
      justify-content: center;
      order: 2;
      margin-top: 20px;
    }
    > span {
      text-align: center;
    }
  }

  &__logo {
    width: 149px;
    height: 50px;
    display: block;
    margin-right: 32px;
  }

  &__nav {
    @media (max-width: $breakpoint-small-max) {
      justify-content: center;
      order: 1;
    }
    flex: 0 1 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    li:not(:last-child) {
      margin-right: 32px;
    }

    a {
      text-decoration: none;
      color: $gray-dark;
      &:hover {
        color: #999;
      }
    }
  }
}

.arrowIcon {
  margin-left: auto;

  span > span > svg > path {
    stroke: var(--colour-version-website);
  }

  @media (max-width: $breakpoint-small) {
    right: 0;
    padding-right: 16px;
    position: absolute;
  }
}

.shop-image {
  width: 24px;
  height: 24px;
  img {
    width: 100%;
  }
}

.applyForm {
  margin-top: 24px;
  height: 70px; // So the interface doesn't jump on error;
  width: 400px;

  @media (max-width: $breakpoint-medium-max) {
    height: auto;
    margin-top: 16px;
    margin-bottom: 8px;
    width: 100%;
  }
}

.appliedForm {
  margin-top: 24px;
  margin-bottom: 16px;
  padding: 16px 32px 16px 32px;
  border: 2px solid $gray;
  text-align: center;

  @media (max-width: $breakpoint-medium-max) {
    height: auto;
    margin-top: 16px;
    margin-bottom: 8px;
  }
}
