@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
@mixin getColumns($count, $gap) {
  -moz-column-count: $count;
  -webkit-column-count: $count;
  column-count: $count;

  -moz-column-gap: #{$gap}px;
  -webkit-column-gap: #{$gap}px;
  column-gap: #{$gap}px;
}

.merchantLinkList {
  h2 {
    text-align: center;
    margin-bottom: 40px;
  }
}

.listWrapper {
  ul {
    text-decoration: none;
    list-style-type: none;
    @include getColumns(5, 16);

    @media (max-width: $breakpoint-medium-max) {
      @include getColumns(4, 16);
    }

    @media (max-width: $breakpoint-small-medium-max) {
      @include getColumns(3, 16);
    }

    @media (max-width: $breakpoint-xsmall-max) {
      @include getColumns(2, 16);
    }

    li {
      break-inside: avoid-column;
      list-style-type: none;
      text-decoration: none;
      margin: 0;
      padding-left: 0;
      margin-bottom: 4px;

      &:not(:first-child) {
        margin-top: 0;
      }
      &:before {
        content: none;
      }

      &.titleItem {
        margin-top: 24px;
        margin-bottom: 16px;

        &:first-child {
          margin-top: 0;
        }

        h3 {
          text-transform: capitalize;
          margin: 0;
        }

        .icon {
          display: none;
        }
      }
    }
  }
}

.link {
  font-family: $global-font-family-tertiary;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: $gray;

  &:hover {
    color: var(--colour-version-website);
    text-decoration: none;

    .icon {
      transition: all 0.1s ease;
      transform: translateX(2px);
      svg path {
        fill: var(--colour-version-website);
      }
    }
  }
}

.icon {
  width: 8px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  transition: all 0.1s ease;

  svg path {
    fill: $gray;
  }
}
