@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.page-progress-bar {
  position: relative;
  height: 3px;
  background-color: $tint-gray;
  box-sizing: border-box;
  &__progress {
    background-color: var(--colour-version-website);
    height: 100%;
    transition: all ease 100ms;
  }
}
