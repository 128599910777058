@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.medium-hero {
  box-sizing: border-box;
  @media (max-width: $breakpoint-xsmall-max) {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.holder {
  position: relative;
  @media (max-width: $breakpoint-small-medium) {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
}

.image {
  height: 260px;
  overflow: hidden;
  position: relative;
  border-radius: 2px;
  @media (max-width: $breakpoint-small-max) {
    height: 200px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    height: 172px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  position: absolute;
  left: 16px;
  right: 16px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  @media (max-width: 360px) {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  h1,
  h2 {
    text-align: center;
  }
  span:nth-child(n + 3) {
    color: $global-inverse-color;
  }
  h1,
  h2:last-child {
    margin-bottom: 0;
  }
}

.top {
  padding: 16px 0;
  width: 80%;
  margin: 0 auto;

  @media (max-width: $breakpoint-small-max) {
    width: 100%;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    padding: 6px 0;
    padding-right: 16px;
    padding-left: 16px;
    box-sizing: border-box;
  }
}
