%heading {
  font-family: $global-font-family-secondary;
  margin-top: 0;
  color: $gray-dark;
  font-weight: bold;
  line-height: 1;
}

%h1 {
  font-size: 32px;
  @media (max-width: $breakpoint-small-max) {
    font-size: 24px;
  }
}

%h2 {
  font-size: 24px;
  @media (max-width: $breakpoint-small-max) {
    font-size: 20px;
  }
}

%h3 {
  font-size: 20px;
  @media (max-width: $breakpoint-small-max) {
    font-size: 18px;
  }
}

%h4 {
  font-size: 16px;
}

%h5 {
  font-size: 14px;
}

%h6 {
  font-size: 12px;
  text-transform: uppercase;
}
