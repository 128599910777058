@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.featured-blog-items {
  &__card {
    display: block;
    position: relative;
    color: $global-inverse-color;
    text-decoration: none;
  }
  &__card:hover {
    img {
      transform: scale(1.05);
    }
    .featured-blog-items__card-image:after {
      background-image: linear-gradient(
        to bottom,
        rgba(28, 28, 28, 0) 0,
        rgba(28, 28, 28, 0.7) 77%,
        rgba(28, 28, 28, 0.8)
      );
    }
  }
  &__card-image {
    height: 240px;
    width: 100%;
    border-radius: $global-border-radius;
    overflow: hidden;
    position: relative;
    @media (max-width: $breakpoint-medium-max) {
      height: 180px;
    }

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: "";
      background-image: linear-gradient(
        to bottom,
        rgba(28, 28, 28, 0) 0,
        rgba(28, 28, 28, 0.6) 77%,
        rgba(28, 28, 28, 0.7)
      );
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s ease;
    }
  }

  &__card-title {
    position: absolute;
    left: 32px;
    right: 32px;
    bottom: 32px;
    color: $global-inverse-color;
    margin: 0;
    @media (max-width: $breakpoint-medium-max) {
      left: 16px;
      right: 16px;
      bottom: 16px;
      font-size: 16px;
    }
  }
}
