@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.footerDesktop {
  font-size: 13px;
  font-weight: 400;
  color: $gray-light;
  background: $global-background;

  &,
  a {
    font-family: $global-font-family-tertiary;
  }

  &__top {
    padding: 20px 0;
  }

  &__top-holder {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    @media (min-width: $breakpoint-medium) {
      justify-content: space-between;
    }
  }
  &__top-left {
    flex: 0 1 25%;
    @media (max-width: $breakpoint-small-max) {
      flex: 0 1 auto;
    }
    a {
      text-decoration: none;
    }
  }
  &__top-right {
    flex: 0 1 75%;
  }
  &__top-list {
    display: flex;
    font-size: 15px;
    color: $gray-light;
    margin: 0;
    @media (max-width: $breakpoint-large) {
      font-size: 12px;
    }
    @media (max-width: $breakpoint-small-max) {
      font-size: 15px;
    }

    @media (min-width: $breakpoint-medium) {
      margin-left: -15px;
    }

    li {
      margin: 0 16px;
      a {
        display: flex;
        color: $gray-light;
        white-space: nowrap;
        align-items: center;
        flex-direction: row;
        &:hover {
          color: $gray;
        }
      }
    }
    .icon-svg {
      margin-right: 10px;
    }
  }

  &__holder {
    background: $gray-dark;
    color: $global-inverse-color;
    padding: 48px 0 37px;
    @media (max-width: $breakpoint-xsmall-max) {
      padding: 16px 0;
    }
    a {
      text-decoration: none;
      color: $tint-gray;
      &:hover {
        color: $gray-lighter;
      }
    }
  }

  &__columns {
    @media (min-width: $breakpoint-small) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    @media (min-width: $breakpoint-medium) {
      flex-wrap: nowrap;
    }
    > div {
      @media (min-width: $breakpoint-small) {
        flex: 1 1 45%;
        width: 50%;
        &:not(:last-child) {
          margin-right: 0;
        }

        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        &:first-child {
          flex: 1 1 100%;
          margin-right: 72px;
        }
      }
      @media (min-width: $breakpoint-medium) {
        flex: 1 1 auto;
        padding-bottom: 0;
      }
      @media (max-width: $breakpoint-small-max) {
        &:first-child {
          margin-right: 0;
        }
      }
    }
  }

  &__column-body {
    flex: 0 1 auto;
    > p {
      margin-top: 0;
      margin-bottom: 15px;
    }
    button {
      width: 100%;
      @media (max-width: $breakpoint-medium) {
        width: 50%;
      }
      @media (max-width: $breakpoint-small) {
        width: 100%;
      }
    }
  }

  &__socialFeedbackSection {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: $breakpoint-small) {
      margin-top: 20px;
    }
  }

  &__column-footer {
    flex: 0 0 auto;
    margin-top: auto;
    margin-right: auto;
    @media (max-width: $breakpoint-small) {
      margin-top: 20px;
    }
  }

  &__column-title {
    font-size: 14px;
    font-family: $global-font-family-secondary;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__icon {
      margin-left: 15px;
      path {
        stroke: #fff;
      }
    }
    @media (max-width: $breakpoint-xsmall-max) {
      padding: 14px 0;
      position: relative;
      margin: 0;
    }
  }

  &__column-nav {
    margin: 0;

    li {
      position: relative;
      list-style: none;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    li:hover {
      a {
        color: white;
      }
    }
  }

  &__bottom {
    padding: 15px 0;
  }

  &__bottom-holder {
    display: flex;
    justify-content: space-between;
    @media (max-width: $breakpoint-small-max) {
      flex-direction: column;
    }
    ul {
      margin: 0;
    }
  }

  &__logo-box {
    display: flex;
    align-items: center;
    flex: 0 1 50%;
    @media (max-width: $breakpoint-small-max) {
      justify-content: center;
      order: 2;
      margin-top: 20px;
    }
    > span {
      text-align: center;
    }
  }

  &__logo {
    width: 149px;
    height: 50px;
    display: block;
    margin-right: 32px;
  }

  &__nav {
    @media (max-width: $breakpoint-small-max) {
      justify-content: center;
      order: 1;
    }
    flex: 0 1 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    li:not(:last-child) {
      margin-right: 32px;
    }

    a {
      text-decoration: none;
      color: $gray-dark;
      &:hover {
        color: #999;
      }
    }
  }

  &__social {
    display: flex;
    flex-direction: row;
    &-items {
      &:last-child {
        padding-bottom: 0px;
      }
    }
  }

  &__social-link {
    width: 100%;
    display: inline-flex;
    align-items: center;
  }

  &__social-icon {
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    flex: 0 0 auto;
    path {
      fill: currentColor;
    }
  }

  &__social-text {
    font-size: 12px;
    color: $tint-gray;
  }
  &__social-title {
    font-size: 15px;
    color: $global-inverse-color;
  }
}

.rating {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  font-size: 12px;
  @media (max-width: $breakpoint-small-max) {
    justify-content: center;
  }
  &__mark {
    font-size: 14px;
    margin-right: 8px;
    font-family: $global-font-family-secondary;
  }
}

.starsNumberContainer {
  display: flex;
  flex-direction: row;
}

.ratingScore {
  padding-right: 8px;
}

.infoText {
  color: #999999;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  font-family: $global-font-family-tertiary;
  padding-bottom: 24px;
}

.ukDisclosure {
  color: $gray-lightest;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  font-family: $global-font-family-tertiary;
  margin-top: 16px;
}

.bottomSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;
  align-items: flex-end;
  gap: 72px;

  @media (max-width: $breakpoint-small-max) {
    gap: 24px;
    flex-wrap: wrap;
  }
}

.rating-text {
  margin-top: 4px;
  line-height: 1;
  white-space: nowrap;
}

.shop-image {
  width: 24px;
  height: 24px;
  img {
    width: 100%;
  }
}

.thuiswinkelIcon {
  img {
    width: 137px;
    height: 44px;
  }
}
.followContainer {
  display: flex;
  justify-content: flex-start;
  gap: 32px;
  flex-wrap: wrap;
}

.share {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
