@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.textFieldInput {
  position: relative;
  border-radius: 100px;
  border: none;
  z-index: 5000;

  @media (max-width: $breakpoint-medium) {
    z-index: 98;
  }

  &:hover {
    .delete {
      visibility: visible;
    }
  }

  input {
    width: 100%;
    padding: 16px !important;
    border-radius: 100px;
    border: 1px solid $tint-gray;
    font-family: $global-font-family !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: $gray-dark !important;
    line-height: 17px;

    &:focus,
    :hover {
      box-shadow: none !important;
      background: white !important;
      border: 1px solid var(--colour-version-website);
      transition: none !important;
    }
  }
}

.icon {
  background-color: var(--colour-version-website);
  width: 32px;
  height: 32px;
  border-radius: 100px;
  top: 4px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}

.iconRight {
  left: auto;
  right: 5px !important;
}

.delete {
  position: absolute;
  left: auto;
  right: 5px !important;
  top: 4px !important;
  bottom: 0;
  background-color: var(--colour-version-website);
  width: 32px !important;
  height: 32px !important;
  border-radius: 100px;
  z-index: 9999;
  visibility: visible !important;
  cursor: pointer;

  svg,
  svg > path {
    fill: white;
    stroke: white;
  }
}
