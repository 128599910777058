@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
$breakpoint-landing-page: 1010px;

.landingPage {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;

  @media (max-width: $breakpoint-landing-page) {
    flex-direction: column;
    overflow-y: auto;
    height: auto;
  }
}

.left {
  display: flex;
  flex-direction: column;
  width: 50%;
  background: $gray-dark;
  background-image: url("https://viper-development-images.s3-eu-west-1.amazonaws.com/blackFriday/pattern.svg");
  background-repeat: no-repeat;
  background-size: 150%;
  background-position: top;
  align-items: center;
  justify-content: center;
  padding: 24px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  @media (max-width: $breakpoint-landing-page) {
    width: 100%;
  }
}

.right {
  width: 50%;
  // background-image: url("https://media.blackfridaynederland.nl/wordpress/20200812161926/Homepage-Sale-Neon-Board-2.gif");
  background-size: cover;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: $breakpoint-landing-page) {
    width: 100%;
  }
}

.header {
  font-family: $global-font-family-secondary;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  color: white;
  margin-top: 60px;
  text-align: center;

  @media (max-width: $breakpoint-landing-page) {
    margin-top: 24px;
    font-size: 24px;
  }
}

.logo {
  @media (max-width: $breakpoint-landing-page) {
    margin-top: 16px;
  }
}

.checkMarks {
  margin-top: 32px;
  font-size: 16px;
  line-height: 23px;
  color: $tint-gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $breakpoint-landing-page) {
    margin-top: 24px;
  }
}

.checkMark {
  margin-bottom: 8px;
}

.checkMarkSpan {
  margin-left: 16px;
}

.countryCards {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;

  @media (max-width: $breakpoint-landing-page) {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
    margin: 24px 8px 16px 8px;

    min-width: 100%;
    max-width: 100%;
  }
}

.countryCard {
  min-width: 43%;
  max-width: 43%;
  margin: 16px;

  @media (max-width: $breakpoint-landing-page) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin: 2px 0px;
  }

  > a {
    padding: 24px;
  }
}

.country {
  display: flex;
  align-items: center;
}

.countryName {
  margin-left: 16px;
  margin-right: 16px;
}
