@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.header-info {
  position: relative;

  &__image {
    padding: 0px;

    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    align-self: center;

    @media (min-width: 1320px) {
      min-width: 1320px;
    }

    height: 214px;
    overflow: hidden;
    position: relative;
    margin-bottom: -80px;
    z-index: 1;
    @media (max-width: $breakpoint-small-max) {
      height: 137px;
      margin: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__body {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    background: $global-background;
    border-radius: $global-border-radius-lg;
    box-shadow: $global-shadow-md;
    padding: 32px;
    color: $gray-light;
    &__content {
      display: flex;
      width: 100%;
    }

    @media (max-width: $breakpoint-small-max) {
      padding: 32px;
      margin: 0 -24px;
    }
    @media (max-width: $breakpoint-xsmall-max) {
      padding: 24px;
      margin: 0 -16px;
    }

    &.icon {
      @media (max-width: $breakpoint-small-max) {
        padding: 32px 16px 16px 16px;
      }
    }

    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border: solid 2px $tint-gray-light;
    margin-right: 28px;
    background: $global-background;
    @media (max-width: $breakpoint-small-max) {
      position: absolute;
      top: -36px;
      width: 56px;
      height: 56px;
    }
  }
  &__icon-image {
    width: 50%;
    height: 50%;
  }
  &__title {
    margin-bottom: 10px;
    font-family: $global-font-family-secondary;
    font-size: 32px;
    color: $gray-dark;
  }
  &__content {
    flex: 1 1 auto;
    p {
      display: inline;
    }
    p:first-of-type {
      margin-top: 0;
    }
  }

  .bodyText {
    h1,
    h2,
    h3 {
      margin: 0 0 16px 0;
      &:not(:first-child) {
        margin: 16px 0 8px 0;
      }
      + p {
        margin: 0;
      }
    }

    @media (max-width: $breakpoint-small-max) {
      h1,
      h2,
      h3 {
        margin: 0 0 8px 0;
        &:not(:first-child) {
          margin: 8px 0 4px 0;
        }
        + p {
          margin: 0;
        }
      }
    }

    p ~ ul,
    p ~ ol {
      margin-top: 8px;
    }
  }
}

.breadcrumbs {
  display: flex;
  align-items: flex-end;
  @media (max-width: $breakpoint-small-max) {
    align-items: center;
  }
}

a.seeMoreLink {
  color: $gray-dark;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: var(--colour-version-website);
  }
}

.share-dropdown {
  @media (max-width: $breakpoint-small-max) {
    display: none;
  }
}

.share-badge {
  display: none;
  @media (max-width: $breakpoint-small-max) {
    display: block;
    margin-bottom: 8px;
  }
}

.top {
  display: flex;
  justify-content: space-between;
}

.left-top {
  display: flex;
  justify-content: space-between;
}

.left {
  display: flex;
  flex-direction: column;
  @media (max-width: $breakpoint-small-max) {
    flex: 1;
  }
}

.right {
  display: flex;
}
