@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.merchantCard {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px;
  background: $global-inverse-color;
  position: relative;
  width: 100%;
  border-radius: 2px;
  min-height: 250px;
  max-height: 250px;

  a {
    text-decoration: none;
  }
}

.header {
  padding: 16px 16px 0 16px;
  margin: -16px -16px 0 -16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  position: absolute;
  left: auto !important;
  right: 0px;
  top: 8px;
}

.campaignStatus {
  position: absolute;
  left: 0;
  top: 8px;
}

.image {
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 60%;
    height: 60%;
    object-fit: contain;
  }
}

.content {
  display: flex;
  text-align: center;
  flex-direction: column;
  flex-grow: 1;

  .merchant {
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 8px;
    font-family: $global-font-family-secondary;
    font-weight: 700;
    @include truncate-text(18, 1);
    color: $tint-gray;
  }

  .description {
    font-family: $global-font-family-tertiary;
    color: $gray-dark;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    line-height: 18px;

    &.emptyDescription {
      display: none;
    }
  }

  .button {
    margin-top: auto;
    margin-bottom: 2px;
  }
}

.outMerchantLink {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $global-font-family-tertiary;
  font-weight: 500;
  font-size: 9px;
  color: $gray-lightest;
  margin-top: 4px;
  margin-bottom: -4px;

  &:hover {
    .merchantLinkText {
      text-decoration: underline;
      color: $gray-lightest;
    }
  }

  .merchantLinkText {
    margin-right: 2px;
  }
}

@mixin responsive-card-768 {
  .merchantCard {
    min-height: 156px;
    flex-direction: row;
    padding: 12px;
  }

  .header {
    padding: 12px 0 12px 12px;
    margin: -12px 16px -12px -12px;
  }

  .image {
    width: 100px;
    height: 100px;

    img {
      width: 80%;
      height: 80%;
    }
  }

  .description {
    margin-bottom: 1px;
  }

  .content {
    text-align: left;
  }

  .label {
    right: 0px;
    top: 4px;
  }

  .status {
    top: 4px;
  }
}

@mixin responsive-card-640 {
  .merchantCard {
    min-height: auto;
  }

  .image {
    width: 150px;
    img {
      width: 90%;
      height: 90%;
    }
  }

  .description {
    margin-bottom: 12px;
  }
}

@mixin responsive-card-460 {
  .image {
    width: 100px;
  }

  .description {
    margin-bottom: 12px;
  }
}

@mixin responsive-card-360 {
  .image {
    img {
      width: 90%;
      height: 90%;
    }
  }

  .description {
    margin-bottom: 12px;
  }
}

@media (max-width: 768px) {
  @include responsive-card-768();
}

@media (max-width: 640px) {
  @include responsive-card-640();
}

@media (max-width: 460px) {
  @include responsive-card-460();
}

@media (max-width: 360px) {
  @include responsive-card-360();
}

.responsive {
  @include responsive-card-768();

  @media (max-width: 640px) {
    @include responsive-card-640();
  }

  @media (max-width: 460px) {
    @include responsive-card-460();
  }

  @media (max-width: 460px) {
    @include responsive-card-360();
  }
}

.normal {
  box-shadow: 0px 2px 0px $tint-gray;
  border: 1px solid $tint-gray;
}

.mobileSearch {
  box-shadow: inset 0px -1px 0px #e0e0e0;
  border: none;
  min-height: 145px;
  max-height: 145px;
  flex-direction: row;
  padding: 12px;
  @include responsive-card-768();
}
