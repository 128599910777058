@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.sidebar {
  box-sizing: border-box;
  height: 100%;
  padding: 16px;
  padding-top: 18px; // Weird padding because of alignment with tags on the left (advice from Conversion Specialist)
  padding-bottom: 26px; // Weird padding because of alignment with tags on the left-bottom (advice from Conversion Specialist)
  width: 100%;
  display: flex;
  flex-direction: column;

  .merchantFeaturesTitle {
    margin-bottom: 16px;
  }

  .merchantFeatures {
    font-family: $global-font-family-tertiary;
    font-size: 14px;
    color: $gray-light;
    margin-bottom: 16px;
  }

  .campaignUsp {
    margin-bottom: 16px;
    .campaignUspTitle {
      margin-bottom: 16px;
    }

    .campaignUspListItem {
      display: flex;
      font-family: $global-font-family-tertiary;
      color: $gray-light;
      font-size: 14px;
      svg path {
        stroke: $brand-secondary;
      }

      > span:first-child {
        margin-top: 3px;
      }

      .uspText {
        margin-left: 8px;
      }

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

  .moreBF {
    margin-top: auto;

    h4 {
      border-top: 1px solid $tint-gray-light;
      margin-top: 24px;
      padding-top: 24px;
      margin-bottom: 16px;
      color: $brand-secondary;
    }

    .dealButtons {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .dealButton {
        width: 100%;
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 12px;
        border: 1px solid $tint-gray-light;
        color: $gray-dark;
        font-weight: 600;
        font-size: 14px;
        box-sizing: border-box;
        background-color: $bg-muted;
        font-family: $global-font-family-secondary;

        &:hover {
          color: var(--colour-version-website);
          text-decoration: none;
        }

        > div {
          margin-left: auto;
          display: flex;
          align-items: center;
          font-family: $global-font-family-tertiary;
          font-size: 12px;

          &.extra {
            color: var(--colour-version-website);
          }

          > span {
            margin-left: 8px;
          }

          svg path {
            fill: currentColor;
          }
        }
      }
    }
  }
}

.top {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.merchantOutLink {
  margin-top: auto;
}
