@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.whenIsHeader {
  position: relative;
}

.content {
  position: relative;
}

.imageContainer {
  height: 418px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.container {
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  margin-top: 16px;
}

.middleSection {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  align-items: center;
}

.subsribeSection {
  display: flex;
  flex-direction: column;
}

.arrow {
  position: absolute;
  margin-left: 680px;
}

.arrowIconMobile {
  @media (max-width: $breakpoint-small-max) {
    transform: translateX(24px);
  }

  @media (max-width: $breakpoint-xsmall) {
    transform: translateX(0px);
  }
}

.arrowText {
  display: block;
  transform: rotate(2.25deg);
  font-family: $global-font-family-quatuary;
  font-size: 18px;
  margin-top: -20px;

  @media (max-width: $breakpoint-small-max) {
    margin-top: -55px;
  }
}

.title {
  display: flex;
  justify-content: center;
  margin-top: 54px;
  margin-bottom: 8px;
  position: relative;
  color: $gray-dark;

  @media (max-width: $breakpoint-small-max) {
    margin-top: 24px;
  }
  @media (max-width: $breakpoint-xsmall-max) {
    margin-top: 24px;
    justify-content: left;
    text-align: left;
  }
}

.sectionTitle {
  margin-bottom: 8px;
  position: relative;
  color: $tertiary-brand-primary;
  text-align: center;
  font-weight: bold;
  font-size: 28px;

  @media (max-width: $breakpoint-small-max) {
    font-size: 22px;
    margin-top: 24px;
  }
  @media (max-width: $breakpoint-xsmall-max) {
    text-align: left;
    margin-top: 8px;
  }
}

.headerSubTitleSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 48px;
  font-family: $global-font-family-tertiary;

  @media (max-width: $breakpoint-small-max) {
    margin-bottom: 24px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    align-items: inherit;
  }
}

.headerSubTitleItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  @media (max-width: $breakpoint-small-max) {
    margin-bottom: 0;
  }
}

.headerSubTitleContainer {
  justify-content: left;
}

.headerSubTitleText {
  margin-left: 8px;
}

.middleSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;

  @media (max-width: $breakpoint-small-max) {
    padding: 16px;
  }
}

.middleSectionHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;

  @media (max-width: 479px) {
    align-items: flex-start;
  }
}

.middleSectionSubTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: $breakpoint-small-max) {
    margin-top: 8px;
  }
}

.middleSectionSubTitleText {
  margin-bottom: 22px;
  font-family: $global-font-family-tertiary;

  @media (max-width: 479px) {
    margin-right: 16px;
    text-align: left;
  }
}

.applyFormContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  justify-content: center;
  width: 100%;

  @media (max-width: $breakpoint-small-max) {
    margin-bottom: 0;
  }
}

.applyForm {
  width: 100%;
  max-width: 480px;

  @media (max-width: $breakpoint-medium-max) {
    [class*="formWrapper"] {
      flex-direction: row;
    }
  }

  @media (max-width: $breakpoint-xsmall-max) {
    width: 100%;
    max-width: 100%;
  }

  @media (max-width: 479px) {
    [class*="formWrapper"] {
      flex-direction: column;
    }
  }
}

.ctaButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  width: 100%;
  max-width: 848px;
  margin-top: 8px;

  @media (max-width: $breakpoint-xsmall-max) {
    gap: 8px;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
}

.ctaButton {
  width: 100%;

  @media (max-width: $breakpoint-xsmall-max) {
    flex-direction: column;
    max-width: 100%;
  }
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e0e0e0;
  margin: 1em 0;
  padding: 0;
  width: 100%;
}
