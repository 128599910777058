@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.module {
  display: flex;
  flex-direction: column;
}

.searchBar {
  width: 100%;
  margin-bottom: 56px;
  box-sizing: border-box;
  display: flex;
  gap: 24px;
  background: $global-background;
  border-radius: $global-border-radius-lg;
  box-shadow: $global-shadow-md;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $breakpoint-small) {
    > div[class*="textFieldInput"] {
      max-width: 500px;
      height: 56px;
      z-index: 1;

      input {
        height: 100%;
      }

      > span[class*="icon"],
      > span[class*="delete"] {
        width: 40px !important;
        height: 40px !important;
        bottom: auto;
        top: 8px !important;
        right: 8px !important;
      }
    }
  }
  @media (max-width: $breakpoint-medium) {
    flex-direction: column;
    padding: 16px;
    > div[class*="textFieldInput"] {
      max-width: 100%;
    }
  }
}

.anchors {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  @media (max-width: $breakpoint-small) {
    display: none;
  }

  .text {
    font-family: $global-font-family-secondary;
    font-weight: 600;
    font-size: 14px;

    @media (max-width: $breakpoint-small-medium) {
      display: none;
    }
  }

  .anchorTags {
    display: flex;
    gap: 8px;
  }
}

.results {
  display: flex;
  flex-direction: column;
  gap: 56px;

  @media (max-width: $breakpoint-small) {
    gap: 32px;
  }
}

@media (max-width: $breakpoint-small) {
  .searchBar {
    margin-bottom: 32px;
  }
  .results {
    gap: 32px;
  }
}
