@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.deal-overview {
  padding-top: 24px;
  @media (max-width: $breakpoint-small-max) {
    padding: 16px 0;
  }
}
.filterBar {
  position: relative;
  height: 64px;
  z-index: 99;
  box-shadow: 0 10px 8px -4px rgba(0, 0, 0, 0.08);
  background-color: var(--colour-version-website-lightAccent);
}

.filterBarWrapper {
  height: 64px;
}

.sticky {
  position: fixed;
  width: 100%;
  top: 0;
  transform: translateY(0);
}

.no-black-friday {
  margin: auto;
  margin-top: 32px;
  margin-bottom: 40px;
  width: 100%;
  text-align: center;
}
.deals-overview {
  background-color: var(--tint-grey-97);
  &__header {
    padding-bottom: 16px;
    background-color: var(--tint-white);
  }
  &__body {
    color: red;
    &__banner {
      margin: 10px 8px 10px 8px;
      flex: 0 1 100%;
      width: 100%;

      @media (max-width: 640px) {
        &__new {
          border-bottom: 1px solid $tint-gray;
          padding: 0px;
          width: 100vw;
          position: relative;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
          margin-top: 0;
          margin-bottom: 0;

          > div > * > * {
            border: none;
            box-shadow: none;
            border-radius: 0;
          }
        }
      }
    }
    &__list {
      margin: -8px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      @media (max-width: 640px) {
        flex-direction: column;
      }
    }
    &__cards {
      box-sizing: border-box;
      width: 20%;
      padding: 8px;
      @media (max-width: 1200px) {
        width: 25%;
      }
      @media (max-width: 959px) {
        width: 33.3333%;
      }
      @media (max-width: 768px) {
        width: 50%;
      }
      @media (max-width: 640px) {
        width: 100%;

        &__new {
          border-bottom: 1px solid $tint-gray;
          padding: 0px;
          width: 100vw;
          position: relative;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw;

          > a {
            border: none;
            box-shadow: none;
          }
        }
      }
    }
  }
}

.loading-spinner {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.deals-overview__tab {
  background-color: white;
  padding-bottom: 8px;
}

.bottomRef {
  position: absolute;
  height: 400px;
  bottom: 0;
}
