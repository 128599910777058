@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.uspContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: $breakpoint-xsmall-max) {
    display: block;
    flex-direction: row;
  }
}

.icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: $breakpoint-xsmall-max) {
    margin-right: 12px;
  }
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;

  font-size: 12px;
  line-height: 14px;
  font-family: $global-font-family-tertiary;
  color: #1c1c1c;
  font-weight: 500;
  text-align: center;
}

.usp {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 25%;
  justify-content: center;
  align-items: center;
  @media (max-width: $breakpoint-large) {
    width: 25%;
  }
  @media (max-width: $breakpoint-small-max) {
    width: 50%;
  }
  @media (max-width: $breakpoint-small-medium) {
    justify-content: left;
    margin-bottom: 16px;
    width: 50%;
  }
  @media (max-width: $breakpoint-small) {
    width: 100%;
    flex-direction: row;
  }
}

.outerWrapper {
  width: 100%;
}

.innerWrapper {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
}
