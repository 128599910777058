@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.uk-margin-small-top {
  margin-top: 16px;
}

.featuredCategories {
  h2 {
    margin: 0px;
  }
}

.wrapper {
  position: relative;
  .flex {
    display: flex;
    column-gap: 12px;
    margin-top: 24px;
  }

  .category {
    box-sizing: border-box;
    width: fit-content;
    border-radius: 16px;
    transition: 0.1s;

    &:hover {
      transform: translate(0, -20px);
    }
  }
}

.icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: $breakpoint-small) {
  [class~="flicking-viewport"] {
    overflow-x: hidden;
    width: 100%;
    box-sizing: border-box;
    padding-left: 12px;
  }
}

.slider {
  display: flex;
  width: 100%;
  flex-direction: row;
  box-sizing: border-box;
  padding-top: 24px;
  padding-bottom: 8px;
}

.buttonWrapper {
  position: absolute;
  top: calc(50% - 8px);
  z-index: 5;
}

.buttonWrapperNext {
  right: 0;
}

.sliderButton {
  @include button-reset();
  opacity: 0.9;
  width: 32px;
  height: 32px;
  background: $gray;
  box-shadow: 0px 2px 0px $gray-dark;
  border-radius: 2px;
  cursor: pointer;
  @media (max-width: $breakpoint-small) {
    display: none;
  }
}
