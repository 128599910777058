@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.stars-rating {
  display: flex;
  min-width: 86px;
  align-items: baseline;
  &__star {
    margin-right: 4px;
  }
  &__hovered {
    path {
      fill: #ffbd03 !important;
    }
    &-empty {
      path {
        fill: rgb(224, 224, 224) !important;
      }
    }
  }
  &__label {
    color: #ffbd03;
    margin-right: 6px;
    font-size: 14px;
  }
}
.full {
  path {
    fill: #ffbd03;
  }
}

.empty {
  path {
    fill: rgb(224, 224, 224);
  }
}
.half {
}
