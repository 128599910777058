@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.menuItemButton {
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
}

.menuItem {
  padding: 16px 0px;
  display: inline-flex;
  align-items: center;
  margin-right: 32px;
  gap: 6px;

  &:hover {
    box-shadow: inset 0px -3px 0px var(--colour-version-website) !important;
  }

  a {
    font-family: $global-font-family-secondary;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: black;
    text-decoration: none;
  }

  .chevron {
    display: flex;
  }

  .content {
    a {
      font-family: $global-font-family-tertiary;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.content {
  display: none;
  background-color: white;
  position: absolute;
  width: 100%;
  left: 0;
  top: 126px;
  z-index: 5000;
  box-shadow: inset 0px 1px 0px $tint-gray, inset 0px -1px 0px $tint-gray;

  .grid {
    display: flex;
    flex-wrap: wrap;

    .column {
      margin-top: 16px;

      li {
        min-width: 227px;
        max-width: 227px;
        margin-top: 4px;
        padding-left: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      li::before {
        content: none;
      }
    }
  }

  .main {
    font-family: $global-font-family-tertiary;
    font-weight: 500;
    font-size: 14px;
    color: var(--colour-version-website);
  }

  .sub {
    font-weight: 400;
    font-size: 13px;
    color: $gray;
  }
}

.hide {
  display: none;
}

.show {
  display: block;
}
