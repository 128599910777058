@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.input-component {
  .uk-margin-small-top {
    margin-top: 8px;
  }
  position: relative;
  position: relative;

  label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 2px;
    color: $gray-dark;
  }
  .icon-svg {
    position: absolute;
    left: 12px;
    bottom: 4px;
    transform: translateY(-50%);
    svg,
    path {
      fill: currentColor;
    }
  }

  input {
    padding-left: 36px;
    background-color: $bg-muted;
    min-height: 36px;
    width: 100%;
    border: none;
    font-size: 16px;
    box-sizing: border-box;
    min-height: 40px;
    font-family: $global-font-family;
  }
}

.input-icon {
  position: absolute;
  left: 12px;
  bottom: 6px;
  transform: translateY(-50%);
  display: flex;
  svg,
  path {
    fill: currentColor;
  }
}
