//
// Brand colors
//
$brand-primary: #e10a14 !default;
$brand-secondary: #00a82f !default;
$brand-secondary-darker: #00631c !default;
$brand-secondary-medium-dark: #009329;
$brand-primary-darker: #85050b !default;
$secondary-brand-primary: #ff6a04 !default;
$secondary-brand-primary-dark: #ea5f00 !default;

$tertiary-brand-primary: #22388a !default;
$tertiary-brand-primary-light: #4f63ac !default;
$tertiary-brand-primary-dark: #10193e !default;

$gray: #474747 !default;
$gray-dark: #1c1c1c !default;
$gray-light: #777777 !default;
$gray-lighter: #7c7c7c !default;
$gray-lightest: #999999 !default;
$gray-88: #f4f4f4 !default;

$tint-gray: #e0e0e0 !default;
$tint-gray-light: #f0f0f0 !default;

$bg-muted: #f7f7f7 !default;

//
// Breakpoints
//
$breakpoint-xsmall: 480px !default;
$breakpoint-small: 640px !default;
$breakpoint-small-medium: 768px !default;
$breakpoint-medium: 960px !default;
$breakpoint-large: 1200px !default;
$breakpoint-xlarge: 1600px !default;
$breakpoint-xxlarge: 1920px !default;

$breakpoint-xsmall-max: ($breakpoint-small - 1) !default;
$breakpoint-small-medium-max: ($breakpoint-small-medium - 1) !default;
$breakpoint-small-max: ($breakpoint-medium - 1) !default;
$breakpoint-medium-max: ($breakpoint-large - 1) !default;
$breakpoint-large-max: ($breakpoint-xlarge - 1) !default;
$breakpoint-large-max-plus: ($breakpoint-xxlarge - 1) !default;

//
// Typography
//
$global-font-family: "Assistant", sans-serif;
$global-font-family-secondary: "Bai Jamjuree", sans-serif;
$global-font-family-tertiary: "Rubik", sans-serif;
$global-font-family-quatuary: "Covered By Your Grace", sans-serif;

$global-font-size: 16px !default;
$global-line-height: 1.438 !default;

$global-xxlarge-font-size: 32px !default;
$global-xlarge-font-size: 24px !default;
$global-large-font-size: 20px !default;
$global-medium-font-size: 16px !default;
$global-small-font-size: 14px !default;
$global-xsmall-font-size: 12px !default;

//
// Colors
//

$global-color: $gray !default;
$global-inverse-color: #fff !default;
$global-muted-color: #999 !default;
$global-emphasis-color: #f6faf2 !default;

$global-link-color: $brand-primary !default;
$global-link-hover-color: darken($global-link-color, 15%) !default;

$global-background: #fff !default;
$global-muted-background: $bg-muted !default;
$global-primary-background: $brand-primary !default;
$global-secondary-background: $brand-secondary !default;

$global-success-background: #32d296 !default;
$global-warning-background: #faa05a !default;
$global-danger-background: #fa3939 !default;

//
// Borders
//

$global-border-width: 1px;
$global-border: #ececec;
$global-border-radius: 2px !default;
$global-border-radius-lg: 12px;

//
// Spacings
//

// Used in margin, section, list
$global-margin: 24px;
$global-small-margin: 16px;
$global-medium-margin: 32px;
$global-large-margin: 40px;
$global-xlarge-margin: 120px;

// Used in grid, column, container, align, card, padding
$global-gutter: 24px;
$global-small-gutter: 16px;
$global-medium-gutter: 32px;
$global-large-gutter: 40px;

//
// Container
//

$container-max-width: 1200px !default;
$container-small-max-width: 740px !default;
$container-medium-max-width: 860px !default;
$container-large-max-width: 1600px !default;

$container-padding-horizontal: 16px !default;
$container-padding-horizontal-s: $global-gutter !default;
$container-padding-horizontal-m: $global-medium-gutter !default;

//
// Buttons
//

$button-hover-background: lighten($gray-dark, 10%);
$button-primary-hover-background: $brand-secondary-medium-dark;
$button-primary-active: #007420;

//
// Border
//
$global-border: #eceaea;

//
// Shadows
//
$global-shadow-md: 0 4px 8px 0 rgba(0, 0, 0, 0.08);

$accordion-item-margin-top: $global-margin !default;
$global-medium-font-size: $global-font-size !default;
$accordion-title-font-size: $global-font-size !default;
$accordion-title-line-height: 1.4 !default;
$accordion-title-color: #00a29c !default;
$accordion-title-hover-color: #00a29c !default;
$accordion-content-margin-top: 8px !default;

// new

$base-heading-font-family: $global-font-family-secondary;
$base-heading-font-weight: bold;
$base-heading-color: $gray-dark;
$base-heading-text-transform: none;
$base-heading-margin-top: $global-medium-margin;

$base-h1-font-size: $global-xxlarge-font-size;
$base-h1-line-height: 1;
$base-h2-font-size: $global-xlarge-font-size;
$base-h2-line-height: 1.26;
$base-h3-font-size: $global-large-font-size;
$base-h3-line-height: 1.25;
$base-h4-font-size: $global-medium-font-size;
$base-h4-line-height: 1.38;
$base-h5-font-size: $global-small-font-size;
$base-h5-line-height: 1.33;
$base-h6-font-size: $global-xsmall-font-size;
$base-h6-line-height: 1;
$base-list-padding-left: 35px;
$base-em-color: inherit;

@mixin truncate-text($max-height, $amount-of-lines) {
  max-height: #{$max-height}px;
  line-height: calc(#{$max-height}px / #{$amount-of-lines});
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $amount-of-lines;
  -webkit-box-orient: vertical;
}

@mixin button-reset {
  box-sizing: border-box;
  font-size: 15px;
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  font-family: $global-font-family;
  cursor: pointer;
}
