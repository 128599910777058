@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.coupon {
  position: relative;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 1.3;
  font-weight: 600;
  font-family: $global-font-family-tertiary;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  justify-content: center;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  cursor: pointer;
  text-transform: uppercase;
  border: 1.5px dashed var(--colour-version-website);
  color: var(--colour-version-website);
  width: 100%;
  height: 48px;
  padding-right: 48px;
  padding-left: 0;
  font-size: 14px;
  z-index: 10;
  background-color: white;

  &.noIcon {
    padding: 0 16px;
  }

  &:hover {
    background-color: var(--colour-version-website-lighten51) !important;
  }

  .icon {
    background-color: var(--colour-version-website-lighten51) !important;
    height: 32px;
    width: 32px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 6px;
    border-radius: 2px;

    svg path {
      fill: var(--colour-version-website);
    }
  }
}

.copied {
  background-color: var(--colour-version-website-lighten51) !important;
}
