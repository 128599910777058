@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.c-modal {
  position: fixed;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  -webkit-overflow-scrolling: touch;
  height: auto;
  max-height: 600px;
  min-width: 600px;
  overflow: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  box-shadow: $global-shadow-md;
  @media (max-width: $breakpoint-xsmall-max) {
    height: 100% !important;
    max-height: 100%;
    width: 100vw !important;
    max-width: 100vw;
    min-width: 100vw;
  }

  &-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000; // This must be at a higher index to the rest of your page content
    transform: translateZ(0);
    background-color: rgba(0, 0, 0, 0.35);
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px;
    line-height: 1;
    background: transparent;
    border: 0;
    box-shadow: 0;
    cursor: pointer;
    transition: transform 0.2s;
    outline: none !important;
    &:hover {
      transform: scale(1.2);
    }
  }

  &__close-icon {
    width: 30px;
    height: 30px;
    fill: transparent;
    stroke: black;
    stroke-linecap: round;
    stroke-width: 3;
  }

  &__body {
    padding: 24px;
    box-sizing: border-box;
  }
}
