@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.content {
  min-height: 250px;
  max-height: 250px;
  width: 536px;
  min-height: 330px;
}

.counter {
  font-size: 15px;
  color: $gray-light;
  margin-bottom: 12px;
}

.filter-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
}

.dropdown {
  &-head {
    padding: 16px 0px;
  }
  &-body {
    overflow-y: auto;
    height: 205px;
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
    padding-top: 8px;
    padding-bottom: 16px;
    margin-left: -16px;
    margin-right: -16px;
    align-content: flex-start;
    > div {
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      box-sizing: border-box;
      padding: 4px 16px;
      width: 50%;
      &[role="button"] {
        cursor: pointer;
      }
      @media (max-width: 479px) {
        width: 100%;
      }
    }
    @media (max-width: $breakpoint-medium-max) {
      min-height: calc(100% - 55px);
    }
    @media (max-width: $breakpoint-xsmall-max) {
      padding: 0;
    }
  }
  &-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $global-inverse-color;
    width: 100%;
    &__action {
      margin: 0;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $tint-gray;
      padding: 16px 24px;
      box-sizing: border-box;
      @media (max-width: $breakpoint-xsmall-max) {
        justify-content: center;
      }
      > li {
        display: flex;
        align-items: center;
      }
    }
  }
}
