@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.modal-navbar {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 1030;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: 0.2s opacity ease-in-out;
  background: rgba(0, 0, 0, 0.35);
  height: 100%;
  &.opened {
    opacity: 1;
    visibility: visible;
  }

  &__close {
    color: $global-inverse-color;
    display: flex;
    align-self: flex-start;
    align-items: center;
    width: fit-content;
    svg,
    span {
      cursor: pointer;
    }
    color: $tint-gray-light;
    path {
      stroke: $global-inverse-color;
    }
    &:hover {
      cursor: pointer;
      span {
        color: var(--colour-version-website);
      }
      path {
        stroke: var(--colour-version-website);
      }
    }
    > span {
      margin-right: 8px;
    }
    &__menu {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 32px;

      @media (max-width: $breakpoint-xsmall-max) {
        margin-bottom: 0;
        flex-direction: row-reverse;
        > div {
          margin-right: 0;
        }
      }
    }
    &__search {
      margin-top: 28px;

      @media (max-width: $breakpoint-small-max) {
        top: 24px;
        color: $gray-dark;

        display: none;

        svg path {
          fill: $gray-dark;
        }
      }
      &:hover {
        color: var(--colour-version-website);
      }
    }

    .modal-search & {
      top: 30px;
      @media (max-width: $breakpoint-small-max) {
        top: 24px;
        color: $gray-dark;
      }
      @media (max-width: $breakpoint-xsmall-max) {
        right: 24px;
      }
    }
  }

  &__open {
    padding: 0;
    border: 0;
    background: none;
    margin: 0;
  }

  &__holder {
    display: initial;
    &__menu {
      &__close {
        width: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        transition: 0.25s all ease-in-out;
        height: 100%;
      }
      &__open {
        width: 400px;
        @media (max-width: $breakpoint-small) {
          width: 90vw;
        }
        transition: 0.25s width ease-in-out;
      }
    }
  }

  &__container {
    position: relative;
    max-width: $container-max-width;
    height: 100%;
    margin: 0 auto;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;

    &__search {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      height: auto;
      top: 0px;
      .modal-navbar-items {
        padding-top: 24px;
        @media (max-width: $breakpoint-small-max) {
          padding-top: 12px;
        }
        @media (min-width: $breakpoint-medium) {
          padding-right: 24px;
        }
      }
      @media (max-width: $breakpoint-small-max) {
        top: 6px;
      }
    }

    &__menu {
      background-color: $gray-dark;
      padding-top: 32px;
      padding-bottom: 32px;
      display: flex;
      flex-direction: column;
    }
    @media (max-width: $breakpoint-xsmall-max) {
      &__menu {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }
    @media (min-width: $breakpoint-small) {
      padding-left: 16px;
      padding-right: 16px;
    }
    @media (min-width: $breakpoint-medium) {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}

.modal-navbar-items {
  width: 100%;
  overflow: auto;
}

.navbar-background {
  width: 100vw;
  height: 80px;
  display: flex;
  background: $gray-dark;
  position: absolute;
  left: 0;
  top: 0;
  @media (max-width: $breakpoint-small-max) {
    height: 74px;
  }
}
