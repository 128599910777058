@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.share-social-dropdown {
  .content {
    padding: 0px 16px;
  }
}

.dropdown {
  @media (max-width: $breakpoint-small-max) {
    display: none;
  }
}

.badges {
  display: none;
  @media (max-width: $breakpoint-small-max) {
    display: block;
  }
}
