@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.mobileNavBar {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;

  .wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    .hamburger {
      all: unset;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .rightText {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-weight: bold;
    font-size: 12px;
    font-family: $global-font-family-secondary;

    .title {
      color: black;
    }

    .subtitle {
      color: var(--colour-version-website);
    }
  }
}

.logo {
  width: 120px;
  height: 34px;
}

.overlay {
  position: absolute;
  z-index: 5000;
  width: 100vw;
  height: 100vh;
  background: $gray-dark;
  opacity: 0.4;
  left: 0;
  top: 0;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px $tint-gray;
}

.topBannerContainer {
  background-color: black;
  width: 100%;
  height: 48px;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 0 8px 8px;
}

.checkLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  min-width: 16px;
  min-height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 8px;
}

.checkLabelText {
  font-family: $global-font-family-secondary;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: white;
  margin-right: 24px;
  white-space: nowrap;
}

.arrowIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 72px;
  height: 8px;
  svg > path {
    stroke: var(--colour-version-website);
  }
}

.menuItems {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.menuItem {
  box-shadow: inset 0px -1px 0px $tint-gray;
  padding: 16px 16px;
  background-color: white;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    color: $gray-dark;
    font-family: $global-font-family-secondary;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    white-space: nowrap;
  }

  .chevron {
    display: flex;
    align-items: center;
  }

  .merchantChevron {
    display: flex;
    align-items: center;
    margin-left: 8px;
    svg > path {
      stroke: var(--colour-version-website);
    }
  }

  .discountText {
    color: var(--colour-version-website);
    font-weight: bold;
    font-size: 12px;
    font-family: $global-font-family-secondary;
  }

  .merchantItemContainer {
    display: flex;
    flex-direction: row;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 0px $tint-gray;
  padding: 12px 16px;
  color: $gray-dark;
  font-family: $global-font-family-secondary;
  font-weight: 600;
  margin-bottom: 24px;

  .backButton {
    @include button-reset();
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
  }
}

.subMenuItem {
  .title {
    padding: 24px 16px 6px 16px;
    font-family: $global-font-family-tertiary;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: var(--colour-version-website);
  }

  .items {
    .link {
      padding: 10px 16px;
      box-shadow: 0px 1px 0px $tint-gray;
      a {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: $gray;
        font-family: $global-font-family-tertiary;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        text-decoration: none;
      }
    }
  }
}

.close {
  @include button-reset();
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 34px;
  background-color: $tint-gray-light;
  border-radius: 2px;

  &:hover {
    background-color: $tint-gray;
  }
}

.search {
  background-color: white;
  overflow: hidden;
  height: 100%;
  width: 100%;

  .top {
    display: flex;
    align-items: center;
    background-color: white;
    z-index: 5001;
    padding: 8px 16px;
    border-top: 1px solid $tint-gray;
    border-bottom: 1px solid $tint-gray;

    .icon {
      display: flex;
      align-items: center;
      margin-right: 16px;
    }

    .input {
      flex-grow: 1;
    }
  }

  .results {
    height: 100%;
    margin: 24px 0px;

    .steps {
      margin-top: 24px;
    }

    .noResults {
      font-family: Bai Jamjuree;
      font-weight: bold;

      font-size: 16px;
      color: $gray-dark;

      li {
        padding-left: 0px;
        color: $gray-light;
        font-weight: normal;

        .icon {
          margin-right: 16px;
        }
      }

      li::before {
        content: none;
      }
    }
  }
}

.searchResultContainer {
  margin-bottom: 34px;
}

.subMenuItems {
  flex-grow: 1;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.searchIsOpen {
  position: fixed;
  z-index: 5001;
}

.titleContainer {
  display: flex;
  flex-direction: row;
}

.amountLabel {
  font-family: $global-font-family-tertiary;
  display: inline-flex;
  justify-content: center;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  background: #f0f0f0;
  font-weight: 700;
  border-radius: 50px;
  font-size: 10px;
  height: 11px;
  width: 18px;
  position: relative;
  top: -11px;
  right: -3px;
}
