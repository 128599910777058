@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.authority {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  @media (max-width: $breakpoint-small-medium-max) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &.noTitle {
    justify-content: center;
  }
}

.title {
  font-family: $global-font-family-secondary;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  @media (max-width: $breakpoint-small-max) {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: bold;
  }
}

.logos {
  display: flex;

  span {
    &:not(:first-child) {
      margin-left: 40px;
    }

    min-width: 100px;
    max-width: 100px;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  @media (max-width: $breakpoint-small-medium-max) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;

    span {
      &:not(:first-child) {
        margin-left: 0;
        margin-top: 40px;
      }
    }
  }
}
