@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.tag {
  @include button-reset();
  border-radius: 999px;
  padding: 0 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $global-font-family-tertiary;
  font-size: 16px;
  color: var(--colour-version-website);
  border: 1px solid $tint-gray;
  background: white;
  min-height: 40px;

  &:hover {
    border-color: var(--colour-version-website);
    text-decoration: none;
  }
}
