@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.sidebar {
  background: $bg-muted;
  padding: 24px;
  border-radius: $global-border-radius;
  > div:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media (max-width: $breakpoint-small-max) {
  .sidebar {
    padding: 24px 16px;
  }
}
