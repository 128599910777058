@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.featured-sidebar-items {
}

.title {
  margin-bottom: 16px;
}

.list {
  font-family: $global-font-family-secondary;
  font-size: 16px;
  font-weight: bold;
  box-shadow: $global-shadow-md;
  border-radius: $global-border-radius;
  margin: 0;
  > * {
    &:hover {
      transform: scale(1);
      box-shadow: none;
    }
  }
  a {
    border-bottom: 1px solid $tint-gray;
    box-shadow: none;
    padding: 8px 16px;
    border-radius: 0;

    &:last-child {
      border-radius: 0 0 $global-border-radius $global-border-radius;
      border: 0;
      img {
        width: 75%;
        height: 75%;
      }
    }
    &:first-child {
      border-radius: $global-border-radius $global-border-radius 0 0;
    }
  }
}
