@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.linkBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: $global-border-radius;
  overflow: hidden;
  box-shadow: 0px 2px 0px $tint-gray;
  border: 1px solid $tint-gray;
}

.imageWrapper {
  width: 100%;
  height: 128px;
  display: flex;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;

  h3 {
    margin-bottom: 16px;
  }
}

.linkList {
  text-decoration: none;
  margin-bottom: 0;

  li {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  a {
    font-family: $global-font-family-tertiary;
    font-size: 14px;
    color: $gray;
    font-weight: 400;
    text-decoration: none;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 8px;
      transition: transform ease 0.1s;
      svg path {
        fill: $gray;
      }
    }

    &:hover {
      color: var(--colour-version-website);

      .icon {
        transform: translateX(2px);
        svg path {
          fill: var(--colour-version-website);
        }
      }
    }
  }
}
