@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.fullPrice {
  position: relative;
  display: flex;
}

.cent {
  font-size: 10px;
  position: relative;
  align-self: right;
  vertical-align: top;
  display: inline-flex;
  right: 6px;
  top: 2px;
}

.dash {
  font-size: 22px;
  position: relative;
  right: 7px;
}
