@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.card {
  position: relative;
  box-shadow: $global-shadow-md;
  background: white;
  padding: 16px 24px;
  border-radius: $global-border-radius-lg;
  width: 100%;
  height: 150px;
  display: grid;
  gap: 24px;
  grid-template-columns: 0.75fr 1px 2fr 1fr;

  &,
  * {
    box-sizing: border-box;
  }
}

.divider {
  height: 100%;
  width: 1px;
  background: $global-border;
}

.merchant {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 140px;
  justify-content: space-between;

  h4 {
    margin: 0;
    @include truncate-text(38, 2);
    min-height: 38px;
    font-size: 14px;
    font-family: $global-font-family-tertiary !important;
    font-weight: 500;
  }
}

.merchantName {
  @include truncate-text(18, 1);
  font-size: 14px;
  font-family: $global-font-family-secondary;
  font-weight: 700;

  &,
  &:hover {
    color: $tint-gray;
    text-decoration: none;
  }
}

.merchantLogo {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 80%;
    height: 80%;
    object-fit: contain;
  }
}

.outMerchantLink {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  font-family: $global-font-family-tertiary;
  font-weight: 500;
  font-size: 9px;
  margin-top: 8px;
  color: $gray-lightest;

  &:hover {
    text-decoration: underline;
    color: $gray-lightest;
  }
}

.usps {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  font-family: $global-font-family-tertiary;
  font-weight: 300;

  > div {
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: 14px;
    color: $gray-light;

    > span {
      @include truncate-text(20, 1);
    }

    svg path {
      stroke: $brand-secondary;
    }
  }
}

.actions {
  position: relative;
  width: 100%;
  min-width: 160px;
  max-width: 300px;
  margin-left: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.buttons {
  margin-top: auto;
  width: 100%;
}

.dealLink {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-grow: 1;

  &,
  &:hover {
    text-decoration: none;
  }
}

.dealUsp {
  font-size: 10px;
  color: $gray-light;
  font-family: $global-font-family-tertiary;
  font-weight: 400;
}

.price {
  text-decoration: none !important;

  .priceBox {
    display: flex;
    justify-content: center;

    .priceOld {
      font-family: $global-font-family-tertiary;
      display: flex;
      align-items: flex-end;
      text-decoration: line-through;
      color: $gray-light;
      font-size: 16px;
      margin-right: 8px;
    }
    .priceNew {
      display: flex;
      font-family: $global-font-family-tertiary;
      font-size: 32px;
      font-weight: 600;
      line-height: 1;
      color: var(--colour-version-website);

      &.french {
        color: $brand-primary;
      }
    }
  }
}

.labels {
  position: absolute;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  gap: 8px;
  top: -10px;
  left: 0;
  width: 100%;

  > * {
    left: unset;
    top: unset;
    position: relative;
    justify-self: center;
  }
}

.discountLabel {
  background: black;
  padding: 0 4px;
  font-size: 13px;
  font-weight: 600;
  font-family: $global-font-family-secondary;
  color: white;
  text-align: center;
  border-radius: 100px;
  justify-self: end;
  margin-right: 40px;
}

@media (max-width: 800px) {
  .card {
    grid-template-columns: 1fr 2fr;
    height: 138px;
    padding: 8px 16px;
    padding-top: 16px;
    gap: 16px;
  }
  .actions {
    gap: 4px;
  }

  .labels {
    .discountLabel {
      margin-right: 16px;
    }
  }

  .merchant {
    min-width: 100px;
  }

  .price {
    .priceBox {
      .priceOld {
        font-size: 14px;
      }
      .priceNew {
        font-size: 24px;
      }
    }
  }
  .usps {
    display: none;
  }
  .divider {
    display: none;
  }
  .merchantLogo {
    height: 56px;
  }
  .dealLink {
    align-items: flex-end;
    text-align: right;
  }
}
