@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.link {
  display: inline-flex;
  align-items: center;

  &,
  &:hover {
    text-decoration: none;
  }

  svg {
    width: 6px;
    margin-left: 8px;
    transition: transform 150ms ease-in-out;
  }
  &--animated {
    &:hover {
      color: $gray-dark;
      svg {
        transform: translateX(4px);
        * {
          stroke: $gray-dark;
        }
      }
    }
  }
  &--primary {
    color: $gray-dark;
    svg {
      * {
        stroke: $gray-light;
      }
    }
    &:hover {
      color: var(--colour-version-website);
      svg {
        * {
          stroke: var(--colour-version-website);
        }
      }
    }
  }
  &--tertiary {
    color: $brand-secondary;
    svg {
      * {
        stroke: $brand-secondary;
      }
    }
    &:hover {
      color: $brand-secondary-darker;
      svg {
        * {
          stroke: $brand-secondary-darker;
        }
      }
    }
  }
  &--secondary {
    color: var(--colour-version-website);
    svg {
      * {
        stroke: var(--colour-version-website);
      }
    }
    &:hover {
      color: var(--colour-version-website-darken7);
      svg {
        * {
          stroke: var(--colour-version-website-darken7);
        }
      }
    }
  }

  &--quaternary {
    color: #fff;
    svg {
      * {
        stroke: #fff;
      }
    }
    &:hover {
      opacity: 0.9;
      color: #fff;
      svg {
        * {
          opacity: 0.9;
          stroke: #fff;
        }
      }
    }
  }

  &--primary-new-colored-simple {
    color: $gray-lightest;
    font-size: 12px;
    font-family: $global-font-family-secondary;
    text-decoration: underline;
    white-space: nowrap;
    svg {
      * {
        stroke: $gray-lightest;
      }
    }
    &:hover {
      color: $gray-lightest;
      font-family: $global-font-family-secondary;
      text-decoration: underline;
      white-space: nowrap;
      svg {
        * {
          stroke: $gray-lightest;
        }
      }
    }
  }

  &--primary-new-colored-black {
    color: $gray-dark;
    font-size: 12px;
    font-family: $global-font-family-secondary;
    font-weight: 700;
    text-decoration: underline;
    white-space: nowrap;
    svg {
      * {
        stroke: $gray-dark;
      }
    }
    &:hover {
      font-family: $global-font-family-secondary;
      text-decoration: underline;
      white-space: nowrap;
    }
  }
}
