@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.select {
  padding: 3px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  position: relative;
  display: flex;

  &--white {
    select {
      appearance: none;
      margin: 0;
      border: none;
      box-sizing: border-box !important;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      font-size: 15px;
      line-height: 1.3;
      font-weight: 600;
      font-family: $global-font-family;
      text-align: center;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      user-select: none;
      justify-content: center;
      position: relative;
      border-radius: 2px;
      padding: 9px 16px;
      padding-right: 32px;
      transition: background-color 0.05s;
      cursor: pointer;

      background-color: #ffffff;
      border: 1px solid $tint-gray;
      color: $secondary-brand-primary;

      &::-ms-expand {
        display: none;
      }

      &:hover {
        border: 1px solid $secondary-brand-primary;
        background-color: #ffffff;
      }

      &:active {
        border: 1px solid $secondary-brand-primary;
      }
    }

    &-icon {
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      position: absolute;
      right: 21px;
      top: 14px;
      text-align: right;
      pointer-events: none;

      svg {
        path {
          stroke: $secondary-brand-primary;
        }
      }
    }
  }

  &--default {
    select {
      outline: none;
      box-shadow: none;

      border: 0 !important; /*Removes border*/
      appearance: none;
      background-position: 82px 7px; /*Position of the background-image*/
      background: transparent;
      font-size: 15px;
      font-family: $global-font-family;
      width: 100%;
      white-space: nowrap;
      box-shadow: 0 1px 0 0 #b3b3b3;

      cursor: pointer;
      border-radius: 0;
      font-weight: 600;
      padding-right: 24px;
      padding-top: 4px;
      padding-bottom: 4px;
      &::-ms-expand {
        display: none;
      }
    }

    &-icon {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      right: 0;
      top: 8px;
      width: 20px;
      margin-right: 5px;
      text-align: right;
      padding-top: 0;
      padding-bottom: 0;
      pointer-events: none;
    }
  }
}

select::-ms-expand {
  display: none;
}

.rounded {
  select {
    border-radius: 999px;
  }
}
