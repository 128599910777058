@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.navbar {
  display: block;
  position: relative;
  background-color: white;
  border-bottom: 1px solid $tint-gray;

  hr {
    margin: 0px;
    background-color: $tint-gray;
  }
}

.navBarWrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
  transform: translateY(-100%);
  transition: transform 0.1s ease-in-out;

  &.sticky {
    transform: translateY(0);
  }
}

.container {
  width: 100%;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0px;
}

.bottom {
  width: 100%;
  border-top: 1px solid $tint-gray;
}

.rightText {
  display: flex;
  flex-direction: column;
  text-align: right;
  font-weight: bold;
  font-size: 12px;
  font-family: $global-font-family-secondary;

  .title {
    color: black;
    text-decoration: none;
  }

  .subtitle {
    color: var(--colour-version-website);
    text-decoration: none;
  }
}

.logo {
  margin-right: 8px;
  width: 180px;
  height: 45px;
  > a {
    display: flex;
    height: 100%;
  }
  svg {
    height: 100%;
    width: 100%;
    @media (max-width: $breakpoint-small-max) {
      height: 40px;
      max-height: 40px;
    }
  }
  @media (max-width: $breakpoint-small-max) {
    width: 150px;
  }
  @media (max-width: 345px) {
    width: 120px;
    margin-right: 16px;
  }
}

.menuItems {
  display: flex;
  justify-content: space-between;
  align-items: center;

  [data-cy="dropdown-content"] {
    left: auto;
    right: 0px;
  }

  div > div > div > button {
    background-color: white;
    padding: 8px 14px;

    &:hover {
      background-color: white;
    }
  }
}

.menuItem {
  padding: 16px 0px;
  display: inline-flex;
  align-items: center;
  margin-right: 32px;

  &:hover {
    box-shadow: inset 0px -3px 0px var(--colour-version-website) !important;
  }

  a {
    font-family: $global-font-family-secondary;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: black;
    text-decoration: none;
  }

  .chevron {
    display: flex;
    margin-left: 6px;
  }

  .content {
    a {
      font-family: $global-font-family-tertiary;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.content {
  display: none;
  background-color: white;
  position: absolute;
  width: 100%;
  left: 0;
  top: 132px;
  z-index: 5000;
  box-shadow: inset 0px 1px 0px $tint-gray;

  .grid {
    display: flex;
    flex-wrap: wrap;

    .column {
      margin-top: 16px;

      li {
        min-width: 227px;
        max-width: 227px;
        margin-top: 4px;
        padding-left: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      li::before {
        content: none;
      }
    }
  }

  .main {
    font-family: $global-font-family-tertiary;
    font-weight: 500;
    font-size: 14px;
    color: var(--colour-version-website);
  }

  .sub {
    font-weight: 400;
    font-size: 13px;
    color: $gray;
  }
}

.search {
  min-width: 400px;

  &:focus-within,
  input:focus {
    .searchResultsContainer {
      display: block;
    }

    .overlay {
      display: block;
    }
  }
}

.searchResultsContainer {
  overflow: auto;
  max-height: calc(100vh - 210px);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 5000;
  max-width: 1144px;
  margin-top: 12px;

  .searchResultsContent {
    overflow: hidden;
    position: relative;
    background-color: $bg-muted;
    padding: 24px;
    border-radius: 2px;
    border: 1px solid $tint-gray;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);

    > div[class*="loader"] {
      position: absolute;
      animation: fadeIn 0.5s linear forwards;
      animation-delay: 200ms;
      opacity: 0;
    }
  }

  @media (max-width: 1144px) {
    margin: 11px 32px 0px 32px;
  }
}

.overlay {
  position: fixed;
  z-index: 4999;
  width: 100vw;
  height: 100vh;
  background: $gray-dark;
  opacity: 0.4;
  left: 0;
  top: 0;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.languageSwitcher {
  display: flex;
  flex-direction: column;
  z-index: 999;
}

.languageSwitcherItem {
  padding: 8px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
