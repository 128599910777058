@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.cta-small {
  &__center {
    padding: 24px;
    background: $global-background;
  }
  &__wrapper {
    border-radius: $global-border-radius;
    text-align: center;
    display: inline-block;
    width: 100%;
    @media (min-width: $breakpoint-small) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__title {
    margin: 16px 0 16px 0;
    font-weight: 600;
    @media (min-width: $breakpoint-small) {
      margin: 0 24px;
    }
  }

  a,
  button {
    white-space: nowrap;
    @media (min-width: $breakpoint-small) {
      width: auto;
    }
    @media (max-width: $breakpoint-small - 1) {
      width: 100%;
    }
  }
}

.icon {
  display: inline-block;
  height: 32px;
  width: 32px;
  svg,
  img {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
  }
}
