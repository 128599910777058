@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.outerWrapper {
  width: 100%;
}

.innerWrapper {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
}

.linkBox {
  padding: 8px;
  box-sizing: border-box;
  width: 25%;
  margin-bottom: 4px;

  @media (max-width: 959px) {
    width: 33.3333%;
  }
  @media (max-width: 768px) {
    width: 50%;
  }
  @media (max-width: 640px) {
    width: 100%;
  }
}
