@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.wrapper {
  background-color: $brand-primary;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  color: white;
  font-size: 12px;
  font-weight: 600;
  column-gap: 16px;
  padding: 8px 0px;
  font-family: $global-font-family-secondary;

  @media (max-width: $breakpoint-small) {
    flex-direction: column;
    padding-top: 18px;
    row-gap: 4px;
    background: linear-gradient(180deg, $brand-primary calc(100% - 22px), rgba(0, 0, 0, 0) 50%);
  }
}

.absolute {
  position: absolute;
}

.titleWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  justify-content: center;
}

.icon {
  min-width: 24px;
  min-height: 24px;
  border-radius: 99px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    path {
      fill: $brand-primary;
    }
  }
}

.title {
  font-size: 14px;
  @include truncate-text(60, 3);
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  padding: 0px 32px;

  @media (max-width: $breakpoint-small) {
    flex-direction: column;
    row-gap: 4px;
  }
}
