@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.discount-subtitle {
  font-family: $global-font-family-secondary;
}

.discount-card {
  display: flex;
  flex-direction: column;
  border-radius: $global-border-radius;
  background: $global-background;
  color: $gray-lighter;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
  font-size: 14px;
  font-weight: 600;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  padding-bottom: 56px;

  &__body {
    flex: 1 1 auto;
  }
  &__link {
    text-decoration: none !important;
  }

  &__sticky {
    transition: max-height 0.3s;
    will-change: max-height;
    z-index: 800;
    overflow: hidden;
    max-height: 0;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    box-shadow: inset 0 -1px 0 0 $tint-gray;
    height: auto;
    box-sizing: border-box;

    @media (max-width: $breakpoint-xsmall-max) {
      bottom: 0px;
      top: auto;
      padding-top: 8px;
      box-shadow: 0px -1px 0px $tint-gray;
    }
    &-opened {
      max-height: 900px;
    }
    &__container {
      display: flex;
      justify-content: space-between;

      @media (max-width: $breakpoint-xsmall-max) {
        flex-direction: column;
        justify-content: none;
      }
    }

    &__content {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__merchant_info {
        display: flex;
        flex-direction: column;

        @media (max-width: $breakpoint-xsmall-max) {
          align-items: center;
        }
      }
      &__title {
        font-family: $global-font-family-tertiary;
        color: $gray-light;
        margin-left: 16px;
        justify-content: space-between;
        white-space: nowrap;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
      }
      &__subtitle {
        font-family: $global-font-family-secondary;
        color: $brand-secondary;
        font-size: 16px;
        margin-left: 16px;
        font-weight: bold;
        margin-top: -4px;
      }
      &__image {
        width: 72px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
          width: 100%;
          height: 80%;
          font-family: "object-fit:contain";
          object-fit: contain;
        }
      }

      @media (max-width: $breakpoint-small-medium-max) {
        width: 100%;
      }

      @media (max-width: $breakpoint-xsmall-max) {
        width: 100%;
        justify-content: center;
      }
    }
    &__actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      @media (max-width: 1079px) {
        .discount-subtitle.hidden {
          display: none;
        }
      }

      button,
      a {
        height: 40px;
        margin-left: 8px;
        margin-bottom: 0px;
        width: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      @media (max-width: $breakpoint-small-medium-max) {
        width: 100%;

        .discount-subtitle {
          display: none;
        }

        button,
        a {
          width: 100%;
        }
      }

      @media (max-width: $breakpoint-xsmall-max) {
        justify-content: center;
      }
    }
  }

  &__coupon {
    box-sizing: border-box;
    font-size: 15px;
    line-height: 1.3;
    font-weight: 600;
    font-family: $global-font-family-tertiary;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    justify-content: center;
    position: relative;
    border-radius: 2px;
    padding: 8px 30px;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    cursor: pointer;
    text-transform: uppercase;
    border: 1px dashed $brand-secondary;
    color: $brand-secondary;
    width: 100%;
    min-width: 120px;
    margin-bottom: 8px;
    &:hover {
      background-color: #caf7d6 !important;
    }
  }

  &__tooltip {
    opacity: 1;
    padding: 10px;
    width: 40% !important;
    position: absolute;
    top: 47px;
    right: 27%;
    background-color: rgba(0, 0, 0, 1);
    z-index: 930;
    overflow: visible;
    color: white;

    &__arrow {
      position: absolute;
      left: 50%;
      top: -5px;
      width: 0;
      height: 0;
      border-bottom: 5px solid rgba(31, 31, 31, 0.8);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
    &__message {
      font-size: 15px;
      padding: auto;
      text-align: center;
      line-height: 20px;
      color: white;
    }
  }
  &__tooltip-sticky {
    opacity: 1;
    padding: 10px;
    position: absolute;
    top: 47px;
    right: 27%;
    background-color: rgba(0, 0, 0, 1);
    z-index: 930;
    overflow: visible;
    color: white;

    &__arrow {
      position: absolute;
      left: 50%;
      top: -5px;
      width: 0;
      height: 0;
      border-bottom: 5px solid rgba(31, 31, 31, 0.8);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
    &__message {
      font-size: 15px;
      padding: auto;
      text-align: center;
      line-height: 20px;
      color: white;
    }
  }

  &__label {
    font-family: $global-font-family-tertiary;
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 2px;
    text-transform: uppercase;
    color: #00a82f;
  }
  &__title {
    font-family: $global-font-family-secondary;
    font-size: 20px;
    font-weight: bold;
    color: $gray-dark;
    margin: 0;
  }
  &__subtitle {
    font-family: $global-font-family-tertiary;
    color: $gray-light;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 24px;
    @media (max-width: $breakpoint-xsmall-max) {
      display: none;
    }
  }
  &__campaignStatus {
    position: absolute;
    z-index: 10;
    left: 0px;
    top: 8px;
  }
  &__image {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85px;
    margin-top: 16px;

    img,
    svg {
      margin: 0 auto;
      max-height: 70%;
    }

    @media (max-width: $breakpoint-medium-max) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  &__content {
    margin-bottom: 8px;
    font-size: 14px;
    text-align: center;
    @media (max-width: $breakpoint-xsmall-max) {
      margin-bottom: 16px;
    }

    a {
      color: $gray;
      text-decoration: none;
    }
  }

  &__action {
    margin: 0 16px;
    flex: 0 0 auto;
    cursor: pointer;
    position: relative;

    button[class*="coupon"] {
      margin-bottom: 4px;
    }

    > a,
    button {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  &__status {
    color: $gray-lighter;
    margin-bottom: 8px;

    &-title {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: bold;
    }
    &-bar {
      position: relative;
      height: 3px;
      background: $tint-gray-light;
      border-radius: 3px;
      overflow: hidden;
    }
    &-bar-filled {
      position: absolute;
      left: 0;
      top: 0;
      height: 3px;
      background: $tint-gray-light;
    }
    &.green {
      color: $brand-secondary;
      .discount-card__status-bar-filled {
        background: $brand-secondary;
      }
    }
    &.orange {
      color: #ff9d0a;
      .discount-card__status-bar-filled {
        background: #ff9d0a;
      }
    }
  }

  &--responsive {
    .discount-card {
      &__body {
        display: flex;
        flex-wrap: wrap;
      }

      &__image {
        order: 2;
        width: 90px;
        height: auto;
        flex: 0 0 90px;
        margin-bottom: 0;
      }

      &__content {
        order: 1;
        flex: 1 1 60%;
        margin-bottom: 0;
      }

      &__action {
        width: 50%;
        margin-left: auto;
      }

      &__status {
        order: 3;
        width: 100%;
        clear: both;
        margin-top: 16px;
        margin-bottom: 16px;
        position: relative;
        &-title {
          position: absolute;
          left: 0;
          top: 28px;
          width: 50%;
        }
      }
    }
  }
  &--modify {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 56px;
      height: 56px;
      border-bottom-right-radius: 100%;
      background-color: $bg-muted;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNC4wMyIgdmlld0JveD0iMCAwIDE2IDE0LjAzIj48dGl0bGU+aGVhcnQtZmlsbGVkPC90aXRsZT48cGF0aCBkPSJNMTQuNzI5LDEuMzQ4QTQuNDYsNC40NiwwLDAsMCw5LjguMzUxYTUuNDE0LDUuNDE0LDAsMCwwLTEuNzYyLDEuNEE1LjUsNS41LDAsMCwwLDYuMjc3LjM1MWE0LjQ2MSw0LjQ2MSwwLDAsMC00LjkyOSwxLDQuNDYxLDQuNDYxLDAsMCwwLDAsNi4zOWw2LjY5LDYuMjkyLDYuNjkxLTYuM2E0LjY0Niw0LjY0NiwwLDAsMCwwLTYuMzg2WiIgZmlsbD0iIzc3Nzc3NyIvPjwvc3ZnPg==);
      background-repeat: no-repeat;
      background-position: 16px 18px;
    }
    .discount-card {
      &__content {
        text-align: center;
        order: 0;
      }
      &__status {
        margin-bottom: 16px;
      }
      &__title {
        font-size: 24px;
        margin-bottom: 16px;
      }
      &__body {
        align-items: center;
        flex-direction: column;
      }
      &__image {
        order: 0;
      }
      &__status-title {
        position: static;
      }
      &__action {
        @media (max-width: $breakpoint-small-max) {
          margin: 0 auto;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-small-max) {
  .discount-card {
    &__sticky {
      &-opened {
        max-height: 900px;
      }

      &__actions {
        padding: 0;
        a,
        button {
          width: auto;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-xsmall-max) {
  .discount-card__sticky__content {
    &__subtitle,
    .hidden {
      white-space: break-spaces;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 0;
    }
  }
  .discount-card {
    padding-bottom: 24px;

    &__sticky {
      &-opened {
        max-height: 900px;
      }
      &__content {
        &__title {
          margin: 0;
        }

        &__subtitle {
          white-space: break-spaces;
        }

        &__image {
          height: 52px;
          margin-left: 12px;
          margin-right: 16px;
          flex-shrink: 0;
        }
      }
      &__actions {
        button,
        a {
          margin: 0;
          margin-right: 10px;

          > span > span:first-child {
            margin-left: 24px;
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 340px) {
  .discount-card {
    &__sticky {
      &__actions {
        min-width: 0px;
      }
      &-opened {
        max-height: 900px;
      }
      &__content {
        &__title {
          margin-right: 12px;
        }
      }
    }
  }
}

@media (max-width: 479px) {
  .discount-card__sticky__content {
    &__subtitle,
    .hidden {
      display: initial;
      white-space: break-spaces;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .discount-card__sticky__actions {
    margin-left: -4px;
    margin-right: -4px;
  }
}

.actions-button-coupon {
  @media (max-width: $breakpoint-xsmall-max) {
    display: none;
  }
}

.actions-button-deals {
  @media (max-width: $breakpoint-xsmall-max) {
    width: 100%;
    margin: 10px 16px 10px 16px;

    button,
    a {
      width: 100%;
    }
  }
}

.label {
  left: auto !important;
  position: absolute;
  z-index: 10;
  right: 8px;
}
