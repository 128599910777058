@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.uk-container {
  &::after {
    content: "unset";
  }
}

.filterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
  column-gap: 4px;

  @media (max-width: 820px) {
    padding: 10px 0 12px 0;
  }
}

.desktop {
  display: none;

  @media (min-width: $breakpoint-small-medium) {
    display: flex;
  }
}

.mobile {
  display: none;

  @media (max-width: $breakpoint-small-medium-max) {
    display: flex;
  }
}

.filter-bar {
  display: flex;
  height: 100%;

  &__clear-filter {
    margin-left: 16px;
    @media (max-width: 820px) {
      margin-left: 10px;
    }
    span {
      color: var(--colour-version-website);
      cursor: pointer;
    }
  }

  &__filter-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 1060px) {
      width: 90%;
    }
    > div {
      margin-right: 8px;
      &:first-child {
        margin-right: 16px;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    .filter {
      &-content {
        margin: 0;
        display: none;
        position: relative;
        &-isActive {
          display: block;
        }
        &-label {
          min-height: 44px;
          box-sizing: border-box;
          line-height: 1;
          display: flex;
          justify-content: space-between;
          padding: 12px 16px;
          background-color: $gray-dark;
          color: $global-inverse-color;
          &.arrowAction {
            justify-content: center;
          }
          > span {
            margin-left: 8px;
          }
          .button-control,
          .arrow-control {
            .icon-svg {
              transform: rotate(0deg);
              margin: 0;
              path {
                stroke: $global-inverse-color;
              }
              &:hover,
              &:active {
                cursor: pointer;
                outline: none;
              }
            }
          }
          .arrow-control {
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            background-color: transparent;
            border: none;
            line-height: 8;
            padding: 16px;
            transform: translateY(-50%);
            &:hover,
            &:active {
              cursor: pointer;
              outline: none;
            }
          }
          &-icon {
            margin-left: 8px;
            transform: rotate(90deg);
          }
        }
        &-clear {
          text-decoration: underline;
          font-size: 13px;
          font-weight: 600;
          color: $tint-gray;
          &:hover {
            text-decoration: none;
          }
        }
      }
      &-label {
        @media (max-width: 800px) {
          display: none;
        }
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        font-family: $global-font-family-secondary;
        color: $gray-dark;
        > span:first-child {
          margin-right: 8px;
        }
        > span {
          display: flex;
        }
        &--mobile {
          background-color: $tint-gray-light;
          justify-content: space-between;
          span {
            transform: rotate(0) translateX(-2px);
            path {
              fill: $gray-dark;
            }
          }
        }
      }
      &-item {
        padding: 2px 4px 0 4px;
        overflow: hidden;
      }
      &-action {
        position: relative;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  &__sort {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .filter__sort {
    &-item {
      display: flex;
      align-items: center;
      font-size: 15px;
      color: $gray-light;
      padding-left: 24px;
    }
    &-filter {
      display: flex;
      align-items: center;
      font-size: 15px;
      color: $gray-light;
      padding-left: 16px;
      min-width: 120px;
      box-sizing: border-box;
      @media (max-width: 859px) {
        display: none;
      }
    }
    &-text {
      @media (max-width: 1060px) {
        display: none;
      }
    }
  }
}

.amount-of-shops {
  margin-right: 12px;
  display: flex;
}
