@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
$mobile-break-width: 839px;

.productDetail {
  box-sizing: border-box;
  background-color: $bg-muted;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;

  @media (max-width: $breakpoint-xsmall-max) {
    margin-top: 16px;
  }
}

.head {
  margin-bottom: 24px;
}

.wrapper {
  display: flex;
  gap: 16px;

  @media (max-width: $mobile-break-width) {
    flex-direction: column;
  }
}

.main {
  flex-grow: 1;
}

.sidebar {
  width: 344px;

  @media (max-width: $mobile-break-width) {
    width: 100%;
  }
}

.breadcrumbs {
  display: flex;
  align-items: flex-start;
}

.adsenseBanner {
  margin-bottom: 16px;
}
