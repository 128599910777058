@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.shadow-card {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
  display: block;
  &--small {
    box-shadow: $global-shadow-md;
  }
  &--medium {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
  }
}
