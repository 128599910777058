@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.filterBar {
  display: inline-flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 12px 16px;
  align-items: center;

  @media (max-width: $breakpoint-medium-max) {
    height: 36px;
  }
}

.numberOfSelectedItems {
  display: flex;
  height: 12px;
  width: 12px;
  line-height: 12px;
  align-items: center;
  padding: 2px;
  background: black;
  color: white;
  font-size: 12px;
  margin-left: 8px;
  border-radius: 100px;
  text-align: center;
  justify-content: center;
  font-weight: 600;
}

.mobileFilter {
  background-color: #fff;
  height: 100%;
  max-height: 100%;
  width: 100vw;
  max-width: 100vw;
  position: fixed;
  box-sizing: border-box;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.header {
  align-items: center;
  background-color: $gray-dark;
  box-sizing: border-box;
  color: $global-inverse-color;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  height: 44px;
  min-height: 44px;
  padding: 12px 16px;
  position: relative;
  text-align: center;
  width: 100%;
  a {
    color: white;
    font-weight: 500;
    text-decoration: underline;
  }
}

.clear {
  text-decoration: underline;
  display: flex;
  align-items: center;
  .numberOfSelectedItems {
    background: white;
    color: $gray-dark;
    text-decoration: none;
  }
}

.leftTitle {
  > span:first-child {
    margin-left: 8px;
    margin-right: 4px;
  }
}

.navButton {
  align-items: center;
  cursor: pointer;
  display: flex;
  .middleTitle {
    display: flex;
    align-items: center;
    margin-left: 8px;
    justify-content: center;
    font-family: $global-font-family-secondary;
    svg {
      transform: rotate(90deg);
      margin-top: 2px;
    }

    > span:first-child {
      margin-right: 4px;
    }
  }
}

.footer {
  border-top: $tint-gray 1px solid;
  padding: 16px !important;
  background-color: #fff;
  margin-bottom: constant(safe-area-inset-bottom);
  box-sizing: border-box;
}

.list {
  flex: 1 1 auto !important;
  overflow: auto;
  .noResults {
    padding: 0px 16px;
    margin-top: 16px;
    display: flex;
  }
}

.parentFilterItem {
  height: 52px;
  padding: 0px 16px;
  border-bottom: $tint-gray 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  &:hover {
    cursor: pointer;
    background: $bg-muted;
  }
  .right {
    display: flex;
    align-items: center;
    color: $gray-light;
    font-size: 11px;
    font-weight: 600;
    .selectedFilters {
      align-items: center;
      display: flex;
      flex: 1;
      max-width: 332px;
      .selectedFilterText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
      }
      @media (max-width: 479px) {
        max-width: 160px;
      }
      @media (max-width: 359px) {
        max-width: 100px;
      }
    }

    > span:first-child {
      margin-right: 6px;
    }
  }
}

.subFilterItem {
  height: 52px;
  padding: 0px 16px;
  border-bottom: $tint-gray 1px solid;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}

.checkbox {
  font-size: 16px;
}

.title {
  font-weight: 600;
}

.searchInput {
  padding: 12px 16px 0px 16px;
}

.filterItemButton {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  justify-content: center;

  .filterItemText {
    margin-left: 12px;
  }
}

.sortButton {
}
