@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.merchantHeaderMain {
  position: relative;
  flex-grow: 1;

  > div:first-child {
    height: 100%;
  }

  .box {
    padding: 24px;
    padding-top: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
  }
}

.info {
  margin: 0 auto;
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 488px;
  text-align: center;
  height: 100%;

  @media (max-width: $breakpoint-xsmall-max) {
    padding: 32px 0;
  }

  .excerpt {
    margin-bottom: 24px;
    font-family: $global-font-family-secondary;
    font-weight: bold;
    color: var(--colour-version-website);
    margin-bottom: 32px;

    @media (max-width: $breakpoint-xsmall-max) {
      margin-bottom: 24px;
    }
  }
}

.campaignStatus {
  position: absolute;
  z-index: 10;
  left: 0px;
  top: 22px; // Weird top because of alignment with text in the sidebar (advice from Conversion Specialist)
}

.label {
  left: auto !important;
  position: absolute;
  z-index: 10;
  right: 0;
  top: 22px; // Weird top because of alignment with text in the sidebar (advice from Conversion Specialist)
}

.logo {
  width: 180px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  > img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.actionButtons {
  min-width: 280px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;

  @media (max-width: $breakpoint-xsmall-max) {
    margin-bottom: 24px;
    min-width: 100%;
  }

  .discountSubtitle {
    margin-bottom: 8px;
    font-family: $global-font-family-tertiary;
    font-size: 14px;
  }
  .coupon {
    margin-bottom: 8px;
  }

  .dealsButton {
    position: relative;
    width: 100%;
    .arrowIcon {
      position: absolute;
      right: -64px;
      transform: rotateZ(145deg);
      top: -12px;

      @media (max-width: $breakpoint-xsmall-max) {
        display: none;
      }
    }
  }
}

.campaignDescriptionTitle {
  color: $gray-lightest;
  font-weight: 700;
  font-family: $global-font-family-secondary;
  margin-bottom: 16px;

  @media (max-width: $breakpoint-xsmall-max) {
    margin-bottom: 8px;
  }
}

.campaignDescription {
  font-family: $global-font-family-tertiary;
  font-size: 14px;
  color: $gray-light;

  > p:last-child {
    margin-bottom: 0;
  }
}

.footer {
  font-family: $global-font-family-tertiary;
  font-size: 12px;
  color: $gray-light;
  border-top: 1px solid $tint-gray-light;
  padding-top: 16px;

  .categoriesContainer {
    text-align: center;
    @include truncate-text(35, 2);
    text-overflow: none;
    > span {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  @media (max-width: $breakpoint-xsmall-max) {
    display: none;
  }
}
