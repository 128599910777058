@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.formWrapper {
  display: flex;
  flex-direction: row;
  @media (max-width: $breakpoint-medium-max) {
    flex-direction: column;
    justify-content: center;
  }

  &.singlePageVersion {
    flex-direction: column;
  }
}

.input {
  margin-right: -1px;
  width: 100%;

  @media (max-width: $breakpoint-medium-max) {
    margin-bottom: 8px;
    margin-right: 0;
  }

  &.singlePageVersion {
    margin-bottom: 8px;
  }
}

.button {
  &.singlePageVersion {
    button,
    a {
      background-color: $gray;
      box-shadow: 0px 2px 0px 0px $gray-dark;

      &:hover {
        background: $gray-dark;
      }

      &:active {
        background-color: $gray-dark;
        box-shadow: 0px 2px 0px 0px $gray-dark;
      }

      &:disabled {
        cursor: not-allowed;
        &:hover {
          background-color: $gray;
        }
      }
    }
  }
}

.successBox {
  font-family: $global-font-family-tertiary;
  font-size: 14px;
  padding: 0px 16px;
  border: 2px solid lighten($gray, 40%);
  border-radius: $global-border-radius;
  display: flex;
  min-height: 46px;
  text-align: center;
  justify-content: center;
  align-items: center;

  &.footerVersion {
    border: 2px solid $gray;
  }

  &.singlePageVersion {
    text-overflow: clip;
    padding: 4px 16px;
  }

  &.whatIs {
    border: 2px solid $gray;
  }
}
