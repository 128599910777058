@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.featured-shops {
  box-sizing: content-box;

  @media (max-width: $breakpoint-small) {
    padding: 0px;
  }

  .title {
    font-size: 20px;
    color: inherit;
    margin-bottom: 0;

    @media (max-width: $breakpoint-xsmall) {
      margin-bottom: 8px;
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    box-sizing: border-box;
    margin: -8px;

    @media (max-width: $breakpoint-small) {
      margin: 0px;
    }

    > * {
      padding: 8px;
      box-sizing: border-box;
      width: 20%;

      @media (max-width: $breakpoint-small) {
        padding: 0px;
      }

      @media (max-width: $breakpoint-large) {
        width: 25%;
      }
      @media (max-width: $breakpoint-small-max) {
        width: 33.3333%;
      }
      @media (max-width: $breakpoint-small-medium) {
        width: 50%;
      }
      @media (max-width: $breakpoint-small) {
        margin: 0px;
        padding: 0px;
        width: 100%;
      }
    }
  }
  .item {
    @media (max-width: $breakpoint-large) {
      &.hide-1200 {
        display: none;
      }
    }
    @media (max-width: $breakpoint-small-max) {
      &.hide-959 {
        display: none;
      }
    }

    @media (max-width: $breakpoint-small-medium) {
      &.hide-1200 {
        display: initial;
      }
      &.hide-959 {
        display: initial;
      }
      &.hide-768 {
        display: none;
      }
    }

    @media (max-width: $breakpoint-xsmall-max) {
      &.hide-768,
      &.hide-959,
      &.hide-1200 {
        display: initial;
      }
      &.hide-640 {
        display: none;
      }
    }
  }
}

.seeMoreCard.new {
  > div {
    min-height: 234px;
    box-shadow: 0px 2px 0px $tint-gray;
    border: 1px solid $tint-gray;
  }

  @media (max-width: $breakpoint-small-medium) {
    > div {
      min-height: 156px;
      height: 156px;
    }
  }
}

.top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-left: 16px;
  flex-wrap: wrap;

  @media (max-width: $breakpoint-xsmall-max) {
    margin-right: 16px;
    margin-bottom: 16px;
  }
}
