@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.featured-blog {
  .title {
    margin-bottom: 36px;
    @media (max-width: $breakpoint-small) {
      margin-bottom: 16px;
    }
  }

  .body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @media (max-width: $breakpoint-small) {
      flex-direction: column;
    }
  }

  .card {
    width: 48%;
    @media (max-width: $breakpoint-small) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
