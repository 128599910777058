@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.homepage-header__slick-slider {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: 1;
  top: 0;
  right: 0;
  @media (max-width: $breakpoint-small-max) {
    position: static;
    width: auto;
    margin: 0 -24px;
    min-height: 280px;
  }
  > div {
    height: 100%;
    > div {
      height: 100%;
      div {
        height: 100%;
        padding: 0;
      }
    }
  }
}
.homepage-header {
  background-color: $bg-muted;
  &-top {
    position: relative;
    z-index: 1;
  }
  &__slick-arrow {
    &-next,
    &-prev {
      left: auto;
      bottom: 8%;
      top: auto;

      > div {
        margin: 0 auto;
      }
      svg {
        > * {
          stroke: #fff;
        }
      }
    }
    &-prev {
      &:hover {
        svg {
          opacity: 0.8;
        }
      }
      right: 130px !important;
      @media (max-width: $breakpoint-small-max) {
        right: auto !important;
        left: 24px;
        bottom: 0;
      }
    }
    &-next {
      &:hover {
        svg {
          opacity: 0.8;
        }
      }
      right: 72px !important;
      @media (max-width: $breakpoint-small-max) {
        right: 24px !important;
        bottom: 0;
      }
    }
  }
  &__title {
    margin-bottom: 32px;
    margin-top: 10px;
    margin-left: -2px;
    @media (max-width: $breakpoint-small-max) {
      margin-bottom: 24px;
    }
    > * {
      font-size: 48px;
      @media (max-width: $breakpoint-small-max) {
        font-size: 28px;
      }
    }
  }

  &-wrap {
    padding: 80px 0 96px 0;
    @media (max-width: $breakpoint-small-max) {
      padding: 32px 0 0;
      position: relative;
      z-index: 1;
    }
  }
  &-info {
    display: flex;
    position: relative;
    height: 150px;

    @media (max-width: $breakpoint-small-max) {
      background-color: $gray-dark;
      height: auto;
      padding: 60px 0 32px 0;
      margin: -28px 0 0 0;
      align-items: flex-start;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        left: -100%;
        top: 0;
        width: 100%;
        height: 100%;
        background: $gray-dark;
      }
      &:before {
        content: "";
        position: absolute;
        right: -100%;
        top: 0;
        width: 100%;
        height: 100%;
        background: $gray-dark;
      }
    }
    &__col {
      min-width: 210px;
      padding-top: 40px;
      width: 50%;
      box-sizing: border-box;
      @media (max-width: $breakpoint-small-max) {
        padding: 0;
        min-width: auto;
        flex: 1 1 50%;
        margin-right: 8px;
      }
      h3 {
        margin-bottom: 0;
        @media (max-width: $breakpoint-small-max) {
          font-size: 14px;
        }
      }
    }
    &__label {
      color: var(--colour-version-website);
      font-size: 11px;
      line-height: 1.44;
      font-weight: bold;
      text-transform: uppercase;
      @media (max-width: $breakpoint-small-max) {
        margin-bottom: 8px;
      }
    }
    &__labelFrench {
      color: var(--colour-version-website-lighten51);
      font-size: 11px;
      line-height: 1.44;
      font-weight: bold;
      text-transform: uppercase;
      @media (max-width: $breakpoint-small-max) {
        margin-bottom: 8px;
      }
    }
  }

  &__cards {
    padding: 0;
    margin-top: 50px;
    list-style: none;
    margin-top: 56px;
    box-sizing: border-box;
    width: 100%;

    @media (max-width: $breakpoint-medium) {
      display: inline-flex;
      justify-content: space-between;
    }
    @media (max-width: $breakpoint-small-max) {
      margin-top: 32px;
    }
    @media (max-width: $breakpoint-xsmall-max) {
      display: flex;
      flex-direction: column;
    }
    > * {
      max-width: 400px;
      height: 72px;
      box-sizing: border-box;
      padding: 24px;
      @media (max-width: $breakpoint-small-max) {
        width: 100%;
        &:hover {
          transform: perspective(1px) translateZ(0) scale(1);
          -webkit-transform: perspective(1px) translateZ(0) scale(1);
          -ms-transform: perspective(1px) translateZ(0) scale(1);
        }
      }
      &:last-child {
        @media (max-width: $breakpoint-small-max) {
          margin-left: 16px;
        }
        @media (max-width: $breakpoint-xsmall-max) {
          margin-left: 0px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 8px;
        @media (max-width: $breakpoint-small-max) {
          width: calc(100% - 32px);
          margin-bottom: 1px;
        }
        @media (max-width: $breakpoint-xsmall-max) {
          margin-right: 0px;
          width: 100%;
        }
      }
    }
  }
  &-bottom {
    background-color: $gray-dark;
    padding: 270px 0 120px 0;
    margin-top: -150px;
    position: relative;
    z-index: 0;
    @media (max-width: $breakpoint-small-max) {
      margin: 0;
      padding: 48px 0;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-image: url("../../../../assets/pattern.svg");
      background-position: 50% 0;
      background-size: 2000px auto;
      opacity: 0.5;
    }
    &__wrap {
      margin-bottom: -16px;
      z-index: 1;
    }
    &__cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: -8px;
      @media (max-width: $breakpoint-small-max) {
        box-shadow: $global-shadow-md;
        border-radius: 2px;
        margin: 0px;
      }
      > div {
        width: 50%;
        padding: 8px;
        box-sizing: border-box;
        @media (max-width: $breakpoint-small-max) {
          width: 100%;
          padding: 0px;
          &:first-child {
            a {
              border-radius: 2px 2px 0px 0px;
            }
          }
          &:last-child {
            a {
              border-radius: 0px 0px 2px 2px;
            }
          }
          &:not(:last-child) {
            border-bottom: 1px solid $bg-muted;
          }
          > a {
            border-radius: 0px;
            box-shadow: none;
            transform: perspective(0px) translateZ(0) scale(1);
            -webkit-transform: perspective(0px) translateZ(0) scale(1);
            -ms-transform: perspective(0px) translateZ(0) scale(1);
            &:hover {
              box-shadow: none;
              transform: perspective(0px) translateZ(0) scale(1);
              -webkit-transform: perspective(0px) translateZ(0) scale(1);
              -ms-transform: perspective(0px) translateZ(0) scale(1);
            }
          }
        }
      }
    }
    &__cards-container {
      width: 50%;
      padding-left: 16px;
      padding-right: 16px;
      box-sizing: border-box;
      @media (max-width: $breakpoint-xsmall-max) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        &:last-child {
          margin-top: 40px;
        }
      }
    }
  }
  &__slider {
    &-item {
      -webkit-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      padding: 0 0 72px 72px !important;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      position: relative;
      transition: transform 0.2s;
      @media (max-width: $breakpoint-small-max) {
        padding: 0 16px 12px 16px !important;
        align-items: center;
      }

      &:after {
        content: "";
        position: absolute;
        background-blend-mode: multiply;
        background-image: linear-gradient(to bottom, rgba($gray-dark, 0), rgba($gray-dark, 0.6));
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }

    &-item-video {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 99;
      top: 50%;
      height: auto !important;
      left: 50%;
      margin-top: -40px;
      margin-left: -40px;
      transition: transform 0.2s;
      background: black;
      border-radius: 50%;
      cursor: pointer;

      > div {
        display: flex;
      }

      &:hover {
        transform: scale(1.05);
      }
    }

    &__slide-container {
      position: relative;
    }
    &-control {
      color: $global-inverse-color;
      height: auto !important;
      position: relative;
      bottom: 25%;
      @media (max-width: $breakpoint-small-max) {
        bottom: 35%;
        left: 0;
        a {
          display: flex;
          justify-content: center;
        }
      }
      left: 6%;
    }
    &-title {
      height: auto !important;
      color: inherit;
      display: flex;
      align-items: baseline;
      z-index: 1;
      @media (max-width: $breakpoint-small-max) {
        max-width: 100%;
        display: flex;
        justify-content: center;
      }
      &:hover {
        color: inherit;
      }
      svg {
        margin-left: 14px;
      }
      path {
        stroke: $global-inverse-color;
      }
    }
    &-title {
      font-size: 32px;
      font-family: $global-font-family-secondary;
      font-weight: bold;
      margin-bottom: 16px;
      @media (max-width: $breakpoint-small-max) {
        font-size: 24px;
      }
    }
  }
}

.all-cards {
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
  @media (max-width: $breakpoint-xsmall-max) {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.video-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.slider-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: inherit;
}
