@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.see-more-card {
  min-height: 300px;
  height: 100%;
  border-radius: 2px;
  @media (max-width: 768px) {
    min-height: 168px;
    height: 168px;
    max-height: 168px;
  }
  min-width: 215px;
  width: 100%;
  background-color: white;
  text-align: left;
  padding: 16px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */

  &__title {
    text-align: center;
    h4 {
      font-size: 14px;
      margin-bottom: 8px;
      font-family: $global-font-family-tertiary;
    }
  }
  &__content {
    align-items: center;
    justify-content: space-between;
  }
  &__CTA-under-text {
    display: flex;
    justify-content: center;
  }
  &__button {
    margin: 32px 0 24px 0;
    @media (max-width: $breakpoint-small-medium) {
      margin: 4px 0 20px 0;
    }
  }

  &--responsive {
    max-height: 166px;
    min-height: 166px;
    padding: 16px;
    width: 100%;
    justify-content: flex-start;
    .see-more-card {
      &__body {
        display: flex;
        align-items: flex-start;
      }

      &__title {
        order: -1;
      }

      &__content {
        display: flex;
        @media (max-width: $breakpoint-small-max) {
          justify-content: space-between;
        }
      }
      &__image {
        order: 2;
        width: 90px;
        height: auto;

        flex: 0 0 90px;
        margin: 0 0 0 10px;
      }

      &__price {
        display: block;
        margin: 0;
        flex: 0 0 50%;
      }

      &__action {
        display: flex;
        align-items: flex-end;
        .button {
          width: 50%;
        }
      }
    }
  }
}
