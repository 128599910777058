@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.label {
  position: relative;
  vertical-align: top;
  background: $gray-dark !important;
  border-radius: 100px;
  font-size: 10px;
  color: #fdf3f3;
  font-weight: bold;
  font-family: $global-font-family-tertiary;
  padding: 1px 6px;
  margin-left: 4px;
  @media (min-width: $breakpoint-small) {
    top: auto;
    bottom: 6px;
  }
}
