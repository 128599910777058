@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.defaults {
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.noResults {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  border-bottom: 1px solid $global-border;

  .steps {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    li {
      display: flex;
      align-items: center;

      ::before {
        content: none;
      }

      .icon {
        width: 16px;
        height: 16px;
        margin-top: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
      }
    }
  }
}

@media (max-width: $breakpoint-small) {
  .defaults {
    gap: 32px;
  }
  .wrapper {
    gap: 24px;
  }

  .noResults {
    padding-bottom: 24px;
  }
}
