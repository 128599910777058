@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.label {
  background-color: rgba(var(--colour-version-website-RGB), 0.15);
  font-size: 12px;
  font-weight: bold;
  color: var(--colour-version-website);
  padding: 6px 10px;
  display: inline-flex;
  text-transform: uppercase;
  border-radius: $global-border-radius;
  font-family: $global-font-family-secondary;
  margin-bottom: 8px;
  position: relative;
}
.label__left-pointy {
  &::before {
    position: absolute;
    left: -28px;
    top: 0;
    bottom: 0;
    content: "";
    border: 14px solid transparent;
    border-right-color: rgba(var(--colour-version-website-RGB), 0.15);
  }
}

.label__right-pointy {
  &::after {
    position: absolute;
    right: -28px;
    top: 0;
    bottom: 0;
    content: "";
    border: 14px solid transparent;
    border-left-color: rgba(var(--colour-version-website-RGB), 0.15);
  }
}
