@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.newsletter-forms {
  &__no-items-selected {
    color: $global-danger-background;
    font-family: Assistant, sans-serif;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.0333333333em;
    text-decoration: inherit;
    text-transform: inherit;
    display: block;
    line-height: normal;
    margin-bottom: 24px;
  }
  &__spam {
    font-size: 13px;
    font-weight: 600;
    color: #999999;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    @media (max-width: $breakpoint-xsmall-max) {
      padding-top: 24px;
      display: flex;
      align-items: initial;
      flex-direction: column;
      &:before {
        content: "";
        height: 1px;
        top: 0;
        background: #e0dfe0;
        left: -100px;
        position: absolute;
        right: -100px;
      }
      button {
        margin-top: 8px;
      }
    }
  }
  &__text-field {
    margin-top: 20px;
    padding-bottom: 16px;
    display: flex;
    border-bottom: 1px solid $tint-gray;
    flex-direction: column;
  }
  &__checkboxes {
    padding-top: 16px;
    margin-bottom: 4px;
    margin-left: -8px;
    margin-right: -8px;
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
    > div {
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;
      padding: 4px 8px;
      width: 50%;
      @media (max-width: 479px) {
        width: 100%;
      }
    }
    @media (max-width: $breakpoint-xsmall-max) {
      margin-bottom: 16px;
    }
  }
}

.form-wrapper {
  height: 100%;

  width: 100%;
}

.inputs {
  display: flex;
  flex-direction: row;
  margin: -8px;
  > div {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
  }
  @media (max-width: $breakpoint-xsmall-max) {
    flex-direction: column;
    > div {
      width: 100%;
      padding: 8px;
    }
  }
}
