@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.breadcrumbs {
  font-size: 12px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  font-family: $global-font-family-tertiary;
  margin-bottom: 8px;

  &__light {
    color: rgba(255, 255, 255, 0.5);
    a {
      color: rgba(255, 255, 255, 0.5);
      &:hover {
        color: $global-inverse-color;
        span {
          stroke: $global-inverse-color !important;
        }
      }
    }
    span {
      stroke: rgba(255, 255, 255, 0.5) !important;
    }

    li {
      &:nth-child(n):last-child {
        span {
          color: rgba(255, 255, 255, 0.3);
        }
      }

      &:not(:last-child) {
        &::after {
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }

  &__dark {
    color: $gray-light;
    a {
      color: $gray-light !important;
      &:hover {
        color: var(--colour-version-website) !important;
        span {
          stroke: var(--colour-version-website) !important;
        }
      }
    }
    span {
      stroke: $gray-light !important;
    }

    li {
      &:nth-child(n):last-child {
        span {
          color: $gray;
        }
      }

      &:not(:last-child) {
        &::after {
          color: lighten($gray-light, 10%);
        }
      }
    }
  }

  li {
    margin: 0;
    display: inline-flex;
    align-items: center;

    &:not(:last-child) {
      &::after {
        margin-left: 8px;
        margin-right: 8px;
        display: block;
        content: "\2014";
      }
    }
  }

  ul {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }

  a {
    text-decoration: none;
    font-family: $global-font-family-tertiary;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    &:hover {
      text-decoration: none;
    }
  }
}

.backButton {
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-xsmall) {
    display: none;
  }
}

.backButtonText {
  margin-left: 8px;
}

@media (max-width: $breakpoint-xsmall-max) {
  .breadcrumb {
    justify-content: flex-start;
  }
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;

  @media (max-width: $breakpoint-small-max) {
    max-width: 180px;
  }
}
