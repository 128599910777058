@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.homepageHeader {
  height: 476px;
  display: flex;
  flex-direction: column;

  @media (max-width: $breakpoint-small-max) {
    height: auto;
  }
}

.countdown {
  color: var(--colour-version-website);
  font-family: $global-font-family-tertiary;
  background-color: white;
  padding: 4px 8px;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 24px;
  border-radius: 2px;
}

.cta {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  margin-top: 16px;
}

.arrow {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 24px;
  margin-left: 300px;

  @media (max-width: $breakpoint-small-max) {
    margin-left: 0px;
    flex-direction: column;
  }
}

.arrowText {
  display: block;
  transform: rotate(2.25deg);
  font-family: $global-font-family-quatuary;
  font-size: 18px;
  margin-left: 18px;
  margin-top: -20px;

  @media (max-width: $breakpoint-small-max) {
    margin-top: -55px;
  }
}

.ctaTitle {
  font-size: 48px;
  margin-top: 32px;
  margin-bottom: 8px;

  @media (max-width: $breakpoint-small-max) {
    font-size: 28px;
  }
  @media (max-width: $breakpoint-xsmall-max) {
    font-size: 24px;
  }
}

.ctaButton {
  margin-top: 40px;
  position: relative;

  @media (max-width: $breakpoint-small-max) {
    margin-top: 28px;
  }
  @media (max-width: $breakpoint-xsmall-max) {
    margin: 0 16px 0 16px;
  }
}

.merchantCount {
  position: absolute;
  right: -8px;
  top: -12px;
  pointer-events: none;
  background: $gray-dark;
  border-radius: 100px;
  font-size: 12px;
  color: #fdf3f3;
  font-weight: bold;
  font-family: $global-font-family-tertiary;
  padding: 1px 6px;
}

.ctaSubtitle {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;

  display: flex;
  margin-top: 42px;
  align-items: center;

  text-decoration-line: underline;

  a {
    font-size: 14px;
    font-family: $global-font-family-secondary;
    font-weight: bold;
  }

  .ctaSubTitleIcon {
    margin-left: 8px;
  }

  @media (max-width: $breakpoint-small-max) {
    margin-top: 20px;
    margin-bottom: 16px;
  }

  &Custom {
    @extend .ctaSubtitle;
    :hover {
      color: var(--custom-colour-version-website) !important;
    }
  }
}

.usps {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  justify-content: center;

  .usp {
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: $gray;
    width: 280px;

    .uspIcon {
      margin-right: 12px;
    }

    @media (max-width: $breakpoint-small-max) {
      width: auto;
    }
  }

  @media (max-width: $breakpoint-small-max) {
    flex-direction: column;
    align-items: center;
    > :not(:last-child) {
      margin-right: 0px;
    }

    margin-bottom: 28px;
  }

  @media (min-width: 1921px) {
    margin-bottom: 16px;
  }
}

.ctaSubtitleCustom {
  color: #014991;
}
