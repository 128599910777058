@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.tab-container {
  position: relative;
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .list {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    min-width: 160px;

    @media (max-width: $breakpoint-small-medium-max) {
      min-width: 100px;
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

.background {
  position: absolute;
  background-color: $bg-muted;
  width: 48px;
  height: 48px;
}

.tab-container a {
  min-height: 48px;
}
