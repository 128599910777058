@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.merchantHeaderSticky {
  width: 100%;
  position: fixed;
  z-index: 400;
  top: 0;
  left: 0;
  height: 56px;
  background: white;
  padding: 8px 0;
  box-shadow: inset 0 -1px 0 0 #e0e0e0;
  display: none;
  box-sizing: border-box;

  &.show {
    display: block;
  }

  > div[class="uk-container"] {
    height: 100%;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    bottom: 0px;
    top: auto;
    height: auto;
    padding: 16px 0;
    box-shadow: inset 0 1px 0 0 #e0e0e0;
  }
}

.container {
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-xsmall-max) {
    flex-direction: column;
    gap: 16px;
  }
}

.logo {
  object-fit: contain;
}

.logoExcerptContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoContainer {
  display: flex;
  align-items: center;
  height: 40px;
  max-width: 120px;
  margin-right: 16px;
}

.head {
  display: flex;
  flex-direction: column;

  @media (max-width: $breakpoint-xsmall-max) {
    text-align: center;
  }

  .name {
    font-family: $global-font-family-tertiary;
    color: $gray-light;
    font-size: 14px;
  }

  .excerpt {
    font-family: $global-font-family-secondary;
    font-size: 16px;
    color: $brand-secondary;
    line-height: 1;
    font-weight: 700;
  }
}

.buttons {
  margin-left: auto;
  display: flex;
  gap: 8px;
  height: 100%;
  align-items: center;

  @media (max-width: $breakpoint-xsmall-max) {
    margin-left: 0;
    width: 100%;
  }

  .discountSubtitle {
    font-family: $global-font-family-tertiary;
    color: $gray-light;
    font-size: 14px;
    text-align: right;
    line-height: 1.1;

    @media (max-width: $breakpoint-small-medium-max) {
      display: none;
    }
  }

  .coupon {
    width: auto;
    height: 40px;
    > span {
      line-height: 1.5;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      display: none;
    }
  }

  .dealsButton {
    span[class*="title"] {
      white-space: nowrap;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      width: 100%;
      max-width: 100%;
    }
  }
}
