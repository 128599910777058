@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.blog-post {
  display: flex;
  align-items: center;
  font-size: 14px;
  &__content {
    span {
      max-height: 40px;
      line-height: normal;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    @media (max-width: $breakpoint-small-max) {
      display: none;
    }
  }
  &__title {
    font-family: $global-font-family-secondary;
    color: $gray-dark;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    @media (max-width: $breakpoint-small-max) {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
  &__title:hover {
    color: var(--colour-version-website);
  }
  &__body {
    margin: auto;
    color: $gray-light;
    width: 100%;
  }
  &__image {
    width: 150px;
    height: 105px;
    margin-right: 24px;
    flex: 0 0 auto;
    overflow: hidden;
    position: relative;

    border-radius: 2px;
    @media (max-width: $breakpoint-small-max) {
      margin-right: 16px;
      width: 64px;
      height: 48px;
    }
    img,
    svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__link {
    color: var(--colour-version-website);
    font-weight: 600;
  }
}
