@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.showForTabletAndAbove {
  @media (max-width: $breakpoint-small-medium) {
    display: none;
  }
}

.showForTabletAndBelow {
  @media (min-width: $breakpoint-small-medium + 1) {
    display: none;
  }
}
