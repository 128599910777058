@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.dealCard {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: $global-inverse-color;
  width: 100%;
  border-radius: 2px;
  height: 312px;

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }
}

.rightSection {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.rightSectionLink {
  padding: 0 16px 8px 16px;
}

.outMerchantLink {
  padding: 0 16px 8px 16px;
}

.leftSection {
  width: 100%;
  padding: 8px 16px 0 16px;
}

.titleWrapper {
  height: 39px;

  @media (max-width: 768px) {
    height: auto;
  }
}

.subtitleWrapper {
  height: 18px;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    height: auto;
  }
}

a.dealCard {
  text-decoration: none;

  &:focus,
  &:focus:hover {
    outline: auto;
    outline-offset: -1px;
    outline-color: var(--colour-version-website);
  }
}

.sponsored {
  position: absolute;
  z-index: 10;
  right: 4px;
  top: 0px;
  font-family: $global-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  color: $gray-lightest;
  text-transform: uppercase;
}

.image {
  width: 100%;
  height: 123px;
  order: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: contain;
    flex: 1;
  }
}

.discountLabel {
  position: absolute;
  background-color: black;
  width: 100%;
  padding: 2px 4px;
  font-size: 13px;
  font-weight: bold;
  font-family: $global-font-family-secondary;
  right: 0;
  bottom: 0;
  color: white;
  text-align: center;
  border-radius: 100px;
  margin-top: auto;
  pointer-events: none;
}

.content {
  width: 100%;
  order: 3;
  .title {
    display: flex;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    height: 38px;
    @include truncate-text(38, 2);
    margin-bottom: 4px;

    h4 {
      line-height: inherit;
      font-size: 14px;
      font-family: $global-font-family-tertiary !important;
      font-weight: 500;
      margin-bottom: 16px;
    }
  }
}
.merchantName {
  height: 18px;
  font-family: $global-font-family-secondary;
  display: flex;
  justify-content: center;
  color: #e0e0e0;
  font-size: 14px;
  width: 100%;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 4px;
  text-decoration: none;
  @include truncate-text(18, 1);
  text-align: center;
}

.discount {
  font-size: 13px;
}

.price {
  order: 2;
  margin-bottom: 2px;
  margin-top: 8px;
  text-decoration: none !important;
  height: 28px;

  .priceBox {
    display: flex;
    justify-content: center;

    .priceOld {
      font-family: $global-font-family-tertiary;
      display: flex;
      align-items: flex-end;
      text-decoration: line-through;
      color: $gray-light;
      font-size: 12px;
      margin-right: 8px;
    }
    .priceNew {
      display: flex;
      align-items: flex-end;
      font-family: $global-font-family-tertiary;
      font-size: 24px;
      font-weight: 700;
      line-height: 1;
      color: var(--colour-version-website);

      &.redPrice {
        color: $brand-primary;
      }
    }
  }
}

.action {
  order: 4;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.outMerchantLink {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $global-font-family-tertiary;
  font-weight: 500;
  font-size: 9px;
  color: $gray-lightest;
  margin-top: auto;

  &:hover {
    .merchantLinkText {
      text-decoration: underline;
      color: $gray-lightest;
    }
  }

  .merchantLinkText {
    margin-right: 2px;
  }
}

@mixin responsive-card-768 {
  .dealCard {
    height: 150px;
    flex-direction: row;
  }

  .leftSection {
    max-width: 136px;
    min-width: 136px;
    padding: 8px 16px 8px 16px;
  }

  .rightSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }

  .rightSectionLink {
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    width: 100%;
    padding: 8px 16px 14px 0;
  }

  .outMerchantLink {
    padding: 0 16px 8px 0;
  }

  .label {
    top: 4px;
  }

  .image {
    margin-top: 0;
    order: 1;
    flex: 1 1 40%;
    height: 70px;
    margin-right: 16px;
  }

  .price {
    width: 100%;
    order: 3;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    flex: 1 1 40%;
    margin-bottom: 0;
    margin: 0px 16px 0px 0px;

    .priceBox {
      display: flex;
    }

    .priceNew {
      order: 1;
      .priceOld {
        order: 2;
      }
    }
  }

  .content {
    order: 2;
    height: 100%;
    text-align: left;
    height: 100%;

    .subtitleWrapper {
      height: 18px;
    }

    .title {
      text-align: left;
      margin: 0px;

      h4 {
        margin: 0px;
      }
    }

    .merchantName {
      font-family: $global-font-family-secondary;
      justify-content: left;
      text-align: left;
    }
  }

  .action {
    height: 100%;
  }
}

@mixin responsive-card-640 {
  .dealCard {
    height: 150px;
    flex-direction: row;
  }

  .leftSection {
    max-width: 152px;
    min-width: 152px;
    padding: 8px 16px 8px 16px;
  }

  .rightSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }

  .rightSectionLink {
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    width: 100%;
    padding: 8px 16px 14px 0;
  }

  .outMerchantLink {
    padding: 0 16px 8px 0;
  }

  .content {
    flex: 1 1 50%;
  }
  .image {
    flex: 1 1 40%;
    height: 70px;
    margin-right: 16px;
    img {
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .action {
    width: 100%;
    height: 100%;
  }
  .price {
    order: 3;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 40px;
    margin: 0px 16px 0px 0px;

    .priceBox {
      display: flex;
    }

    .priceNew {
      order: 1;
      .priceOld {
        margin-top: 2px;
        order: 2;
      }
    }
  }
}

@media (max-width: 768px) {
  @include responsive-card-768();
}

@media (max-width: 640px) {
  @include responsive-card-640();
}

.responsive {
  @include responsive-card-768();

  @media (max-width: 640px) {
    @include responsive-card-640();
  }
}

.normal {
  box-shadow: 0px 2px 0px $tint-gray;
  border: 1px solid $tint-gray;
}

.mobileSearch {
  box-shadow: inset 0px -1px 0px #e0e0e0;
  border: none;
  height: 150px;
  flex-direction: row;

  @include responsive-card-768();

  .price {
    height: 40px;
  }
}
