@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 12px;

  &.four {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }

  @media (max-width: $breakpoint-small) {
    &:not(.isOpen) {
      > *:nth-child(n + 4) {
        display: none;
      }
    }
  }
}

.toggleView {
  @include button-reset();
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $global-font-family-secondary;
  font-weight: 600;
  font-size: 14px;
  color: black;
  gap: 8px;
  padding: 8px 0px;
  margin-top: 8px;

  &:hover {
    text-decoration: underline;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg path {
      stroke-width: 1.5px;
    }
  }

  &.open {
    .icon {
      transform: rotate(180deg);
    }
  }
}
