@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.header {
  display: flex;
  background: $global-background;
  border-radius: $global-border-radius-lg;
  box-shadow: $global-shadow-md;
  padding: 32px;
  color: $gray-light;
  margin-top: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  box-sizing: border-box;

  h1 {
    margin: 0;
  }

  > div[class*="breadcrumb"] {
    ul {
      text-align: left;
      justify-content: flex-start;
    }
  }

  @media (max-width: $breakpoint-small-max) {
    padding: 16px;
    margin-top: 16px;
  }
}

.details {
  width: 100%;
  margin-top: 32px;
  display: flex;
  gap: 32px;
  align-items: flex-start;

  @media (max-width: $breakpoint-small-max) {
    gap: 16px;
    margin-top: 8px;
    flex-direction: column;
  }
}

.image {
  width: 198px;
  flex-shrink: 0;
  border-radius: $global-border-radius-lg;
  background: white;
  box-shadow: $global-shadow-md;
  padding: 24px;
  box-sizing: border-box;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $breakpoint-small-max) {
    width: 100%;
    aspect-ratio: auto;
    height: 180px;
  }
}

.compare {
  width: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}
