@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.status {
  display: flex;
  align-items: center;
  font-size: 11px;
  line-height: 11px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 4px;
  height: 20px;
  font-family: $global-font-family-tertiary;
  font-style: normal;
  size: 11px;

  &.green {
    &,
    &:before {
      background-color: #cceed5;
      color: $brand-secondary;
    }
  }

  &.orange {
    &,
    &:before {
      background-color: #ffe1cd;
      color: #ff5c00;
    }
  }

  &:before {
    content: "";
    height: 100%;
    width: 6px;
    right: 0;
    top: 0;
    margin-right: -3px;
    position: absolute;
    transform: skew(-15deg);
  }
}
