@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.timer {
  border-radius: 99px;
  background-color: $gray-dark;
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  min-width: 130px;
}

.time {
  display: inline-block;
}

.timeUnit {
  font-size: 8px;
  font-weight: 600;
  color: $gray-88;
  margin-left: 1px;
}
