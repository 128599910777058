@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.banner:hover {
  text-decoration: none;

  .title,
  .title ~ span {
    text-decoration: underline;
  }

  .image img {
    transform: scale(1.05);
  }
}

.banner,
.holder {
  font-family: $global-font-family-tertiary;
  height: 226px;
  box-sizing: border-box;

  @media (max-width: $breakpoint-small-medium-max) {
    height: auto;
  }
}

.holder {
  box-shadow: 0px 2px 0px $tint-gray;
  border: 1px solid $tint-gray;
  background: $global-background;
  border-radius: $global-border-radius;
  color: $gray-light;
  overflow: hidden;
  align-items: center;
  @media (min-width: $breakpoint-small-medium) {
    display: flex;
  }
  @media (max-width: $breakpoint-xsmall-max) {
    height: auto;
  }
}

.image {
  height: 100%;
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .imageWrapper {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    text-align: center;
    text-decoration: none;
    justify-content: center;

    img {
      transition: 100ms transform ease-in-out;
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
  @media (max-width: $breakpoint-small-medium-max) {
    display: inline-block;
    height: auto;
    border-bottom: 1px solid $tint-gray-light;
    img {
      max-width: 60%;
    }
  }
  @media (max-width: 560px) {
    img {
      max-width: 100%;
    }
  }
}

.body {
  height: 100%;
  box-sizing: border-box;
  flex: 1 1 auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-right: none;
  border-left: 1px solid $tint-gray-light;
  @media (max-width: $breakpoint-small-medium-max) {
    padding: 16px;
    border: none;
  }
}

.title {
  margin: 0 0 8px;
}

.label {
  color: $global-inverse-color;
  background: $brand-primary;
  border-radius: $global-border-radius;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 4px 8px;
  margin-bottom: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 112px;
  height: 56px;
  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    display: none;
  }
}

.content {
  margin-bottom: auto;

  > span {
    font-family: $global-font-family-tertiary;
    font-weight: 400;
  }

  @media (max-width: $breakpoint-small-medium-max) {
    margin-bottom: 24px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    text-align: center;
    margin-bottom: 16px;
  }
}

.footer {
  flex: 0 0 auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  .button {
    margin-top: auto;
    @media (min-width: $breakpoint-small) {
      width: auto;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      width: 100%;
    }
  }
}

.rowReverse {
  flex-direction: row-reverse;
  .body {
    border-left: none;
    border-right: 1px solid $tint-gray-light;
  }
}

@mixin banner-small() {
  &,
  .holder {
    height: auto;
  }

  .holder {
    display: block;
    height: auto;
  }

  .title {
    font-size: 20px;
  }

  .image {
    display: inline-block;
    height: auto;
    border-bottom: 1px solid $tint-gray-light;

    img {
      max-width: 100%;
    }
  }

  .body {
    padding: 16px;
    border: none;
  }

  .logo {
    display: none;
  }

  .content {
    text-align: center;
    margin-bottom: 16px;
  }

  .footer {
    .button,
    .button > div {
      width: 100%;
    }
  }
}

.small {
  @include banner-small();
}
