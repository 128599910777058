@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.blog-overview {
  &__holder {
    @media (min-width: $breakpoint-small-medium) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  &__content {
    margin-bottom: 20px;
    @media (min-width: $breakpoint-small-medium) {
      flex: 0 1 65%;
    }
  }
  &__sidebar {
    margin: 0px -16px;
    @media (min-width: $breakpoint-small-medium) {
      flex: 0 1 35%;
      margin: 0 0 0 40px;
    }
  }
  .posts-list {
    > div {
      padding: 16px 0;
      border-bottom: 1px solid $tint-gray;
      @media (max-width: $breakpoint-small-max) {
        padding: 10px 0;
      }
      &:first-child {
        padding-top: 0;
      }
    }
  }
}
