@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.navBarWrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
  transform: translateY(-100%);
  transition: transform 0.1s ease-in-out;

  &.sticky {
    transform: translateY(0);
  }
}

.mobileNavBar {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: 100%;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;

    .wrapper {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 16px;

      .hamburger {
        @include button-reset();
        height: 100%;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .logo {
        width: 120px;
        height: 34px;
      }
    }

    .rightText {
      display: flex;
      flex-direction: column;
      text-align: right;
      font-weight: bold;
      font-size: 12px;
      font-family: $global-font-family-secondary;

      .title {
        color: black;
      }

      .subtitle {
        color: var(--colour-version-website);
      }
    }
  }

  .menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 5001;
    width: 320px;
    height: 100vh;
    background-color: white;
    overflow: hidden;

    .top {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-shadow: inset 0px -1px 0px $tint-gray;

      button {
        all: unset;
        height: 26px;
        width: 26px;
        background-color: $tint-gray-light;
        padding: 4px 6px;
        border-radius: 2px;
      }
    }
  }

  .overlay {
    position: absolute;
    z-index: 5000;
    width: 100vw;
    height: 100vh;
    background: $gray-dark;
    opacity: 0.4;
    left: 0;
    top: 0;
  }
}

.menuItems {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.menuItem {
  width: 100%;
  box-shadow: inset 0px -1px 0px $tint-gray;
  padding: 12px 16px;
  background-color: white;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    color: $gray-dark;
    font-family: $global-font-family-secondary;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
  }

  .chevron {
    display: flex;
    align-items: center;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 0px $tint-gray;
  padding: 12px 16px;
  color: $gray-dark;
  font-family: $global-font-family-secondary;
  font-weight: 600;
  margin-bottom: 24px;

  button {
    all: unset;
  }

  .backButton {
    display: flex;
    align-items: center;

    .backText {
      margin-left: 8px;
    }
  }
}

.subMenuItem {
  .title {
    padding: 24px 16px 6px 16px;
    font-family: $global-font-family-tertiary;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: var(--colour-version-website);
  }

  .items {
    .link {
      padding: 10px 16px;
      box-shadow: 0px 1px 0px $tint-gray;
      a {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: $gray;
        font-family: $global-font-family-tertiary;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        text-decoration: none;
      }
    }
  }
}

.search {
  background-color: white;
  overflow: hidden;
  height: 100%;
  width: 100%;

  .top {
    display: flex;
    align-items: center;
    background-color: white;
    z-index: 5001;
    padding: 8px 16px;
    border-top: 1px solid $tint-gray;
    border-bottom: 1px solid $tint-gray;
    gap: 4px;

    button {
      all: unset;
    }

    .icon {
      @include button-reset();
      display: flex;
      align-items: center;
      justify-content: center;

      width: 32px;
      height: 32px;
      background-color: $tint-gray-light;
      border-radius: 50px;
      padding: 5px;

      svg path {
        stroke: $gray;
      }
    }

    .input {
      flex-grow: 1;
    }
  }

  .results {
    height: 100%;
    margin: 24px 0px;

    .steps {
      margin-top: 24px;
    }

    .noResults {
      font-family: Bai Jamjuree;
      font-weight: bold;

      font-size: 16px;
      color: $gray-dark;

      li {
        padding-left: 0px;
        color: $gray-light;
        font-weight: normal;

        .icon {
          margin-right: 16px;
        }
      }

      li::before {
        content: none;
      }
    }
  }
}

.searchResultContainer {
  margin-bottom: 34px;
}

.subMenuItems {
  flex-grow: 1;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
