@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
$buttonVariants: "primary-default" $global-inverse-color $global-inverse-color,
  "primary-brand" $global-inverse-color $global-inverse-color, "primary-inverted" $gray-dark $gray-dark,
  "secondary" $gray-dark $gray-dark, "secondary-inverted" $gray-dark $gray-dark,
  "tertiary" $gray-dark (var(--colour-version-website-darken7)),
  "tertiary-inverted" $global-inverse-color $global-inverse-color,
  "link-primary" $gray-dark var(--colour-version-website),
  "link-secondary" var(--colour-version-website) (var(--colour-version-website-darken7)),
  "link-tertiary" $brand-secondary (darken($brand-secondary, 7%)),
  "link-quaternary" $global-inverse-color (darken($global-inverse-color, 20%)), "white" "#FFFFFF";

@each $name, $color, $colorHover in $buttonVariants {
  .button--#{$name}--icon-filled {
    svg path {
      fill: $color;
    }
    &:hover svg path {
      fill: $colorHover;
    }
  }

  .button--#{$name}--icon-outline {
    svg path {
      stroke: $color;
    }
    &:hover svg path {
      stroke: $colorHover;
    }
  }

  .button--#{$name}--icon-outline-fill {
    svg path {
      fill: $color;
      stroke: $color;
    }
    &:hover svg path {
      fill: $colorHover;
      stroke: $colorHover;
    }
  }
}

a.button,
a.button--primary-default,
.button,
.button--primary-default {
  margin: 0;
  border: none;
  box-sizing: border-box !important;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-size: 15px;
  line-height: 1.3;
  font-weight: 600;
  font-family: $global-font-family;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  user-select: none;
  justify-content: center;
  position: relative;
  border-radius: 2px;
  padding: 8px 24px;
  transition: background-color 0.05s;
  cursor: pointer;
  color: $global-inverse-color;
  background-color: $brand-secondary;

  &-shadow {
    box-shadow: 0px 2px 0px 0px rgba(0, 147, 41, 1);
  }

  @media (max-width: $breakpoint-small-max) {
    width: 100%;
  }
  .icon-svg {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &:hover,
  .button--primary-default:hover {
    text-decoration: none;
    background-color: $brand-secondary-medium-dark;
  }

  &--primary-default-gradient {
    background: linear-gradient(180deg, $brand-secondary 17.19%, $brand-secondary-medium-dark 100%);
    border-radius: 3px;

    .title {
      text-decoration: underline;
      text-underline-offset: 5px;
      text-decoration-color: var(--colour-version-website);
    }
  }

  &--primary-new-overview {
    background: var(--colour-version-website-lighten51);
    box-shadow: inset 0px -2px 0px var(--colour-version-website);
    border: 1.5px solid var(--colour-version-website);
    border-radius: 8px;

    .icon-left {
      @media (max-width: $breakpoint-small-max) {
        margin-right: 0px;
        svg {
          width: 24px;
        }
      }
    }

    &:hover {
      background-color: var(--colour-version-website);

      .icon-left {
        path {
          stroke: white;
        }
      }

      [class*="count-label"] {
        color: var(--colour-version-website);
        background: white !important;
      }

      .title {
        color: white;
      }
    }

    [class*="count-label"] {
      background: var(--colour-version-website) !important;
      @media (max-width: $breakpoint-xsmall-max) {
        font-size: 8px;
      }
    }

    .title {
      color: var(--colour-version-website);
      font-family: $global-font-family-tertiary;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;

      @media (max-width: $breakpoint-xsmall-max) {
        font-size: 13px;
      }
    }
  }

  &--primary-new-overview-inverted {
    background: $bg-muted;
    box-shadow: 0px 2px 0px $bg-muted;
    border-radius: 8px;

    .icon-left {
      @media (max-width: $breakpoint-xsmall-max) {
        margin-right: 0px;
        svg {
          width: 24px;
        }
      }

      path {
        stroke: $gray-dark;
      }
    }

    &:hover {
      background-color: $bg-muted;
    }

    [class*="count-label"] {
      @media (max-width: $breakpoint-xsmall-max) {
        font-size: 8px;
      }
    }

    .title {
      color: $gray-dark !important;
      font-family: $global-font-family-tertiary;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;

      @media (max-width: $breakpoint-xsmall-max) {
        font-size: 13px;
      }
    }
  }

  &--primary-default-inverted-gradient {
    font-family: $global-font-family-tertiary;
    @media (min-width: $breakpoint-small) {
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
    }
    color: $gray-dark !important;
    background: linear-gradient(180deg, $global-background 17.19%, #f2f2f2 100%);
    border: 2px solid $tint-gray;
    border-radius: 3px;

    .title {
      text-decoration: underline;
      text-underline-offset: 5px;
      text-decoration-color: var(--colour-version-website);
      text-decoration-thickness: 2px;
    }
  }

  &--primary-brand {
    background-color: $gray-dark;
    &:hover {
      background-color: lighten($gray-dark, 10%);
    }
  }

  &--primary-inverted,
  &--secondary-inverted {
    color: $gray-dark !important;
    background-color: $global-background;
    &:hover {
      background-color: $global-muted-background;
    }
  }

  &--primary-inverted-border {
    font-family: $global-font-family-tertiary;
    @media (min-width: $breakpoint-small) {
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
    }
    color: $gray-dark !important;
    background: linear-gradient(180deg, $global-background 17.19%, #f2f2f2 100%);
    border: 2px solid $tint-gray;
    border-radius: 3px;
    &:hover {
      background: darken($global-background, 5%);
    }
  }

  &--secondary {
    color: $gray-dark !important;
    background-color: $tint-gray-light;

    &:hover {
      background-color: darken($tint-gray-light, 7%);
    }
  }

  &--white {
    color: $secondary-brand-primary;
    background-color: #ffffff;
    border: 1px solid $tint-gray;

    &:hover {
      border: 1px solid $secondary-brand-primary;
      background-color: #ffffff;
    }

    &:active {
      border: 1px solid $secondary-brand-primary;
    }
  }

  &--tertiary {
    color: var(--colour-version-website) !important;
    padding: 8px 16px;
    font-family: $global-font-family-secondary;
    background-color: transparent;
    -webkit-box-shadow: inset 0px 0px 0px 2px var(--colour-version-website);
    -moz-box-shadow: inset 0px 0px 0px 2px var(--colour-version-website);
    box-shadow: inset 0px 0px 0px 2px var(--colour-version-website-lighten51);
    &:hover {
      -webkit-box-shadow: inset 0px 0px 0px 2px var(--colour-version-website-lighten51);
      -moz-box-shadow: inset 0px 0px 0px 2px var(--colour-version-website-lighten51);
      box-shadow: inset 0px 0px 0px 2px var(--colour-version-website-lighten51);
      color: var(--colour-version-website) !important;
      background-color: var(--colour-version-website-lighten51) !important;
    }
  }

  &--tertiary-inverted {
    color: $global-inverse-color;
    background-color: transparent;
    -webkit-box-shadow: inset 0px 0px 0px 2px var(--colour-version-website);
    -moz-box-shadow: inset 0px 0px 0px 2px var(--colour-version-website);
    box-shadow: inset 0px 0px 0px 2px var(--colour-version-website);

    &:hover {
      -webkit-box-shadow: inset 0px 0px 0px 2px var(--colour-version-website-darken7);
      -moz-box-shadow: inset 0px 0px 0px 2px var(--colour-version-website-darken7);
      box-shadow: inset 0px 0px 0px 2px var(--colour-version-website-darken7);
      background-color: var(--colour-version-website-darken35) !important;
    }
  }

  &--primary-new,
  &--primary-new-big {
    font-family: $global-font-family-secondary;
    font-size: 14px;
    font-weight: 700;
    background-color: $brand-secondary;
    height: 40px;
    overflow: hidden;
    box-shadow: 0px 2px 0px 0px $button-primary-active;
    transition: all 0.2s ease;
    padding: 8px 16px;

    .title {
      text-align: center;
      width: calc(100% - 40px);
      margin-right: 16px;
    }

    .icon-right {
      margin-right: -16px;
      width: 40px;
      height: 40px;
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      > span {
        transition: all 0.2s ease;
      }
    }

    .icon-svg {
      z-index: 1;
      width: 100%;
    }

    &:before {
      content: "";
      height: 100%;
      position: absolute;
      left: calc(100% - 40px);
      top: 0;
      width: 100%;
      background: $brand-secondary-medium-dark;
      transition: all 0.2s ease;
    }

    &:hover {
      &:before {
        transform: translateX(calc(-100% + 40px));
      }
      .icon-right {
        > span {
          transition: all 0.2s ease;
          transform: translateX(2px);
        }
      }
    }

    &:active {
      &:before {
        background: $button-primary-active;
      }
    }

    &-big {
      height: 48px;

      .title {
        width: calc(100% - 48px);
      }

      .icon-right {
        width: 48px;
        height: 48px;
      }

      &:before {
        left: calc(100% - 48px);
      }

      &:hover {
        &:before {
          transform: translateX(calc(-100% + 48px));
        }
      }
    }
  }

  &--primary-new-colored {
    font-family: $global-font-family-secondary;
    font-size: 18px;
    font-weight: 700;
    background-color: var(--colour-version-website);
    height: 56px;
    overflow: hidden;
    box-shadow: 0px 2px 0px 0px var(--colour-version-website-darken7);
    transition: all 0.2s ease;
    padding: 8px 16px;
    border-radius: 4px;
    color: $global-background;

    .title {
      text-align: center;
      width: calc(100% - 56px);
      margin-right: 16px;
    }

    .icon-right {
      margin-right: -16px;
      width: 56px;
      height: 56px;
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      > span {
        transition: all 0.2s ease;
      }
    }

    .icon-svg {
      z-index: 1;
      width: 100%;
    }

    &:before {
      content: "";
      height: 100%;
      position: absolute;
      left: calc(100% - 56px);
      top: 0;
      width: 100%;
      background: var(--colour-version-website-darken16);
      transition: all 0.2s ease;
    }

    &:hover {
      background-color: var(--colour-version-website);
      &:before {
        transform: translateX(calc(-100% + 56px));
      }
      .icon-right {
        > span {
          transition: all 0.2s ease;
          transform: translateX(2px);
        }
      }
    }

    &:active {
      &:before {
        background: var(--colour-version-website-darken7);
      }
    }
  }

  &--primary-generic {
    font-family: $global-font-family-secondary;
    font-size: 14px;
    font-weight: 600;
    background-color: $tertiary-brand-primary;
    height: 48px;
    overflow: hidden;
    transition: all 0.2s ease;
    padding: 8px 16px;
    border: 1.5px solid $tertiary-brand-primary-light;
    box-shadow: 0px 2px 0px $tertiary-brand-primary-dark;

    @media (max-width: $breakpoint-small-max) {
      font-size: 12px;
    }

    .title {
      text-align: left;
      width: calc(100% - 40px);
      margin-right: 16px;
    }

    .textRight {
      font-family: $global-font-family-quatuary;
      font-size: 18px;
      white-space: nowrap;
      font-weight: 400;

      @media (max-width: $breakpoint-small-max) {
        font-size: 16px;
      }

      @media (max-width: $breakpoint-xsmall-max) {
        font-size: 15px;
      }
    }

    &:hover {
      background-color: lighten($tertiary-brand-primary, 10%);
    }

    .icon-right {
      margin-right: -16px;
      width: 40px;
      height: 40px;
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      > span {
        transition: all 0.2s ease;
      }
    }

    .icon-svg {
      z-index: 1;
      width: 100%;
    }

    &:before {
      content: "";
      height: 100%;
      position: absolute;
      left: calc(100% - 40px);
      top: 0;
      width: 100%;
      transition: all 0.2s ease;
    }

    &:hover {
      &:before {
        transform: translateX(calc(-100% + 40px));
      }
      .icon-right {
        > span {
          transition: all 0.2s ease;
          transform: translateX(2px);
        }
      }
    }

    &:active {
      &:before {
        background: $tertiary-brand-primary-light;
      }
    }

    &-big {
      height: 48px;

      .title {
        width: calc(100% - 48px);
      }

      .icon-right {
        width: 48px;
        height: 48px;
      }

      &:before {
        left: calc(100% - 48px);
      }

      &:hover {
        &:before {
          transform: translateX(calc(-100% + 48px));
        }
      }
    }
  }

  &--primary-new-colored-uk-custom {
    font-family: $global-font-family-secondary;
    font-size: 18px;
    font-weight: 700;
    background-color: #014991;
    height: 56px;
    overflow: hidden;
    box-shadow: 0px 2px 0px 0px #013f7e;
    transition: all 0.2s ease;
    padding: 8px 16px;
    border-radius: 4px;
    color: $global-background;

    .title {
      text-align: center;
      width: calc(100% - 56px);
      margin-right: 16px;
    }

    .icon-right {
      margin-right: -16px;
      width: 56px;
      height: 56px;
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      > span {
        transition: all 0.2s ease;
      }
    }

    .icon-svg {
      z-index: 1;
      width: 100%;
    }

    &:before {
      content: "";
      height: 100%;
      position: absolute;
      left: calc(100% - 56px);
      top: 0;
      width: 100%;
      background: #023f7c;
      transition: all 0.2s ease;
    }

    &:hover {
      background-color: #014991;
      &:before {
        transform: translateX(calc(-100% + 56px));
      }
      .icon-right {
        > span {
          transition: all 0.2s ease;
          transform: translateX(2px);
        }
      }
    }

    &:active {
      &:before {
        background: #013f7e;
      }
    }
  }

  &--primary-new-colored-simple {
    font-family: $global-font-family-secondary;
    font-size: 14px;
    font-weight: 700;
    background-color: var(--colour-version-website);
    box-shadow: 0px 2px 0px 0px var(--colour-version-website-darken7);

    &:hover {
      background: var(--colour-version-website-darken16);
    }

    &:active {
      background-color: var(--colour-version-website-darken7);
      box-shadow: 0px 2px 0px 0px var(--colour-version-website-darken7);
      &:before {
        height: calc(100% + 2px);
        border-radius: 2px;
      }
    }

    &:disabled {
      cursor: not-allowed;
      &:hover {
        background-color: var(--colour-version-website);
      }
      &:active {
        &:before {
          height: 2px;
          border-radius: 2px;
        }
      }
    }
  }

  &--primary-new-colored-small {
    font-family: $global-font-family-secondary;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    background-color: var(--colour-version-website);
    height: 36px;
    overflow: hidden;
    box-shadow: 0px 2px 0px 0px var(--colour-version-website-darken7);
    transition: all 0.2s ease;
    padding: 8px 16px;
    border-radius: 2px;
    color: white;

    .title {
      text-align: center;
      width: calc(100% - 36px);
      margin-right: 16px;
    }

    .icon-right {
      margin-right: -16px;
      width: 36px;
      height: 36px;
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      > span {
        transition: all 0.2s ease;
      }
    }

    .icon-svg {
      z-index: 1;
      width: 100%;
    }

    &:before {
      content: "";
      height: 100%;
      position: absolute;
      left: calc(100% - 36px);
      top: 0;
      width: 100%;
      background: var(--colour-version-website-darken16);
      transition: all 0.2s ease;
    }

    &:hover {
      background-color: var(--colour-version-website);
      &:before {
        transform: translateX(calc(-100% + 36px));
      }
      .icon-right {
        > span {
          transition: all 0.2s ease;
          transform: translateX(2px);
        }
      }
    }

    &:active {
      &:before {
        background: var(--colour-version-website-darken7);
      }
    }
  }

  &--primary-new-colored-medium {
    font-family: $global-font-family-secondary;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    background-color: var(--colour-version-website);
    height: 40px;
    overflow: hidden;
    box-shadow: 0px 2px 0px 0px var(--colour-version-website-darken7);
    transition: all 0.2s ease;
    padding: 8px 16px;
    border-radius: 2px;
    color: white;

    .title {
      text-align: center;
      width: calc(100% - 40px);
      margin-right: 16px;
    }

    .icon-right {
      margin-right: -16px;
      width: 40px;
      height: 40px;
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      > span {
        transition: all 0.2s ease;
      }
    }

    .icon-svg {
      z-index: 1;
      width: 100%;
    }

    &:before {
      content: "";
      height: 100%;
      position: absolute;
      left: calc(100% - 40px);
      top: 0;
      width: 100%;
      background: var(--colour-version-website-darken16);
      transition: all 0.2s ease;
    }

    &:hover {
      background-color: var(--colour-version-website);
      &:before {
        transform: translateX(calc(-100% + 40px));
      }
      .icon-right {
        > span {
          transition: all 0.2s ease;
          transform: translateX(2px);
        }
      }
    }

    &:active {
      &:before {
        background: var(--colour-version-website-darken7);
      }
    }
  }

  &--FAB {
    padding: 0;
    width: 40px;
    height: 40px;
  }

  &--small {
    padding: 8px 18px;
  }

  &--link {
    &-primary,
    &-secondary,
    &-tertiary,
    &-quaternary {
      &,
      &:hover {
        padding: 0;
        background: transparent;
      }
    }

    &-primary {
      color: $gray-dark;

      &:hover {
        color: var(--colour-version-website);
      }
    }
    &-secondary {
      color: var(--colour-version-website);

      &:hover {
        color: var(--colour-version-website-darken7) !important;
      }
    }
    &-tertiary {
      color: $brand-secondary;

      &:hover {
        color: darken($brand-secondary, 7%) !important;
      }
    }
    &-quaternary {
      color: $global-inverse-color;

      &:hover {
        color: darken($global-inverse-color, 20%) !important;
      }
    }
  }
}

.icon-left {
  margin-right: 8px;
  display: inherit;
}

.icon-right {
  margin-left: 8px;
  display: inherit;
}

.icon-center {
  margin: 0 8px;
  display: inherit;
}

.animated {
  svg {
    transition: transform 150ms ease-in-out;
  }
  &:hover {
    svg {
      transform: translateX(4px);
    }
  }
}

.no-select {
  outline-color: transparent;
  outline-style: none;
}

.rounded {
  border-radius: 999px;
  padding: 8px 16px 8px 16px;
}

.filterDropDown {
  width: 150px;
  .icon-svg {
    width: 100%;
    justify-content: center;
    .icon-right {
      margin-top: 2px;
    }
  }
}
