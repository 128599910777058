@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
                 @import "src/shared/styles/placeholder.scss";
.blog-body-text {
  @media (max-width: $breakpoint-xsmall-max) {
    padding: 0 !important;
  }
  &__container {
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
    display: inline-flex;
    flex-direction: row;
    @media (max-width: $breakpoint-xsmall-max) {
      flex-direction: column;
      padding: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__content {
    flex: 1 1 72%;
    box-sizing: border-box;
    padding-right: 8%;

    @media (max-width: $breakpoint-xsmall-max) {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
    }
    &__footer {
      margin-top: 48px;
      border-top: 1px solid $tint-gray;
      padding: 16px 0 0 0;
    }
  }
  &__sidebar {
    flex: 1 1 27%;
    @media (max-width: $breakpoint-xsmall-max) {
      width: 100%;
    }
  }
  @media (max-width: $breakpoint-xsmall-max) {
    .blog-body-text {
      flex-direction: column;
      &__content {
        width: 100%;
      }
      &__sidebar {
        width: 100%;
      }
    }
  }
}

.meta {
  color: $gray-light;
  font-size: 14px;
  margin-bottom: 24px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  .metaItem {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    color: $gray-light;
    font-family: $global-font-family-tertiary;
    font-weight: 400;

    > span:first-child {
      margin-right: 8px;
    }

    svg {
      path {
        fill: currentColor;
      }
    }
  }

  @media (max-width: $breakpoint-small-max) {
    gap: 12px;
  }
}
